import React, { useLayoutEffect, useState } from "react";
import { Grid, TextField, Paper, TableContainer, TableBody, Checkbox } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from "@mui/icons-material/Edit";
import '../billing/BillingModule.css';
//import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    GET_CUSTOMER_DATA,
    UPDATE_CUSTOMER_DATA,
    getPreUrlAccordingToCart,
    SUCCESS,
    OfferType,
    CART_INITIAL_STATE,
    CUSTUPDATE, isRoleAllowed, GETSINGLECONFIGS, CUSTLOYALITYPOINT, PARTILBILLAUDIT, printDebugLog,
    isFeatureAllowed,
    CUSTOMERAPP,
    GETVIDEOPATH,
    GETCUSTDUES,
    printInfoLog,
    ASSOCIATE_CONCEPT,
    isNumberEmpty
} from "../../constants/constant";
import axios from "axios";
import DialogBox from "../global/DialogBox";
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Radio, Typography
} from "@mui/material";
import CustomDataGrid from "../global/CustomDataGrid";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { snpAxios } from "../global/api";
import YouTubeVideo from "../global/YouTubeVideo";
import SingleFab from "../global/fab/SingleFab";
import { SCREEN_CUSTOMER_DETAILS } from "../../constants/VideoConstant";
import Loader from "../global/loader/Loader";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import { useNavigate } from "react-router-dom";
import EnterButton from "../global/EnterButton";
import ThreeDText from "../global/ThreeDText";

export default function Customer({ showDialogBoxWithPaper }) {
    const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const navigate = useNavigate();
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);

    const INITIAL_CUSTOMER = {
        id: "",
        custName: "",
        emailId: "",
        mob: "",
        address: "",
        royalityPoint: 0.0,
        pointDenom: 0,
        pointDenomVal: 0.0,
        pointRealCostPer: 0.0,
        pointValue: 0.0,
        tDues: 0.0,
        pAudit: [],
        custCompanyName: "",
        custGstDetails: "",
        cartDeliveryAllowed: false,
        associateId: "",
        assosiateAvailable: true,
        associateAmtUsed:0.0,
    };


    const [isDataLoaded, setDataLoaded] = useState(false);
    const [clearDuesLoader, setClearDuesLoader] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loyaityPoints, setLoyaityPoints] = useState(false);
    const [partilBillAvail, setPartialBillAudit] = useState(false);
    const [assoicateConcept, setAssociateConcept] = useState(false);
    const [videoModel, setVideoModel] = useState({ show: false, path: "" });

    const [customerSingleData, setSingleCustomerData] = useState(INITIAL_CUSTOMER);
    const [clearDues, setClearDues] = useState(false);


    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        printDebugLog("handleCheckboxChange", name, checked);
        if (name === 'assosiateAvailable') {
            setSingleCustomerData({
                ...customerSingleData,
                assosiateAvailable: checked,
            })
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'mob') {
            const mobNumber = value.replace(/[^0-9]/g, "");
            setSingleCustomerData({
                ...customerSingleData,
                [name]: mobNumber,
            });
        } else {
            setSingleCustomerData({
                ...customerSingleData,
                [name]: value,
            });
        }
        //console.log("setSingleCategoryData-> ",categorySingleData);
    };

    const columns = [
        { field: "custName", headerName: "Name", width: 120 },
        { field: "mob", headerName: "Ph", width: 110 },
        { field: "custCompanyName", headerName: "Company Name", width: 130 },
        { field: "custGstDetails", headerName: "GST", width: 120 },
        { field: "royalityPoint", headerName: "L.Point", width: 90 },
        {
            field: "pointDenomStr",
            headerName: "L. Formula Set",
            width: 90
        },
        {
            field: "tDues",
            headerName: "Dues",
            width: 90
            , valueGetter: (params) => params.row.tDues, renderCell: (params) => (
                <span style={{ color: params.value === 0 ? '#0f633b' : "#7a1904" }}>{params.value}</span>
            ),
        },
        {
            field: "edit",
            headerName: "Edit",
            width: 90,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            if (isRoleAllowed(CUSTUPDATE)) {
                                handleCellClick(event, cellValues);
                            } else {
                                showDialogBoxWithPaper("You Are not allowed to Update Customer.Contact the Owner");
                            }
                        }}
                    ></EditIcon>
                );
            },
        },
    ];

    const columnsBasic = [
        { field: "custName", headerName: "Name", width: 120 },
        { field: "mob", headerName: "Ph", width: 110 },
        {
            field: "edit",
            headerName: "Edit",
            width: 90,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            if (isRoleAllowed(CUSTUPDATE)) {
                                handleCellClick(event, cellValues);
                            } else {
                                showDialogBoxWithPaper("You Are not allowed to Update Customer.Contact the Owner");
                            }
                        }}
                    ></EditIcon>
                );
            },
        },
    ];

    const columnsAssociate = [
        { field: "custName", headerName: "Name", width: 120 },
        { field: "mob", headerName: "Ph", width: 110 },
        { field: "associateId", headerName: "Assocaite", width: 110 },
        { field: "associateAmtUsed", headerName: "Used Amount", width: 110 },
        {
            field: "edit",
            headerName: "Edit",
            width: 90,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            if (isRoleAllowed(CUSTUPDATE)) {
                                handleCellClick(event, cellValues);
                            } else {
                                showDialogBoxWithPaper("You Are not allowed to Update Customer.Contact the Owner");
                            }
                        }}
                    />
                );
            },
        },
    ];

    const handleCellClick = (param, event) => {
        //console.log("handleCellClick : ",event.row);
        setSingleCustomerData({
            ...customerSingleData,
            ...event.row,
        });
        //
        //setClearDues(event.row.pDues > 0);
        setEdit(true);
        setShowDialog(true);
    };

    const isValid = () => {
        let isCustomerDataValid = true;

        if (!customerSingleData.custName || !customerSingleData.mob) {
            isCustomerDataValid = false;
        }

        return isCustomerDataValid;
    }

    const handleCustomerUpdate = async () => {
        setFormSubmitted(true);
        if (!isValid()) {
            return;
        }

        let catgUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + UPDATE_CUSTOMER_DATA;
        let filledData = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                custId: customerSingleData.id,
                custMob: customerSingleData.mob,
                custName: customerSingleData.custName,
                address: customerSingleData.address,
                custEmail: customerSingleData.emailId,
                custGstDetails: customerSingleData.custGstDetails,
                custCompanyName: customerSingleData.custCompanyName,
                pointDenom: parseInt(customerSingleData.pointDenom),
                pointDenomVal: parseFloat(customerSingleData.pointDenomVal),
                pointValue: parseFloat(customerSingleData.pointValue),
                cartDeliveryAllowed: customerSingleData.cartDeliveryAllowed,
                associateId: customerSingleData.associateId,
                assosiateAvailable: customerSingleData.assosiateAvailable,
                pointRealCostPer: parseFloat(customerSingleData.pointRealCostPer) > 0 ? parseFloat(customerSingleData.pointRealCostPer) : parseFloat(CART_INITIAL_STATE.pointMethod.pointRealCostPer),
            },
        };
        //console.log("handleCustomerUpdate => ",filledData);
        snpAxios.post(
            catgUrl,
            filledData
        )

            .then((response) => {
                //console.log("responses => ", response.data);
                setDataLoaded(true);
                handleDialogClose();
                setFormSubmitted(false);
                if (response.data.status === SUCCESS) {
                    getCustomerData();
                }
            })
            .catch((error) => {
                setDataLoaded(true);
                console.log("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getCustomerData = async () => {
        let custUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_CUSTOMER_DATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            },
        };
        setDataLoaded(false);
        snpAxios
            .post(custUrl, data)
            .then((response) => {
                setDataLoaded(true);
                if (response.data.status === SUCCESS) {
                    setCustomerData(response.data.jcJson.value_2);
                }
            })
            .catch((error) => {
                setDataLoaded(true);
                console.log("Error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getYoutubeVideo = (videoName, lang) => {
        let cUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GETVIDEOPATH}?adminId=${CART_INITIAL_STATE.apiToken}&name=${videoName}&lang=${lang}`;
        let data = {};
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    setVideoModel({ ...videoModel, path: response.data });
                } else
                    setVideoModel({ ...videoModel, path: '' });
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
                setVideoModel({ ...videoModel, path: '' });
            });
    };

    useLayoutEffect(() => {
        printDebugLog("useLayoutEffect customer")
        //  console.log('Printing Login Details : ',CART_INITIAL_STATE);
        getYoutubeVideo(SCREEN_CUSTOMER_DETAILS, 'hi');
        getSingleConfigs(CUSTLOYALITYPOINT);
        getSingleConfigs(PARTILBILLAUDIT);
        getSingleConfigs(ASSOCIATE_CONCEPT);
        getCustomerData(0, 5);
    }, [storeCartType, storeActiveShopHash, storeApiUrl]);

    const handleViewBills = (val) => {
        printDebugLog("handleViewBills : ", val);
        navigate("/dashboard/billentry", { state: { tableProps: {}, sBillNo: val, billType: -1, isSearchByShowBills: true } });
    };

    const renderDialogDues = () => {
        return (
            <React.Fragment>
                <Grid container spacing={0}
                    alignItems="center" sx={{
                        padding: 0, ml: 3, mr: 3, mt: 3, mb: 3
                    }} >
                    <Grid item md={12}>
                        <Typography variant="p" color="#02056e" fontWeight='bold'>Remaining Amt To Paid ₹{customerSingleData.tDues}</Typography>
                    </Grid>
                </Grid>
                {customerSingleData.pAudit.length > 0 &&
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" className="rate_card">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Bill</TableCell>
                                    <TableCell align="center">Accept By</TableCell>
                                    <TableCell align="center">Dues</TableCell>
                                    <TableCell align="center">View</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {customerSingleData.pAudit.map((val) => {
                                    return (
                                        <TableRow
                                            className="rate_card--row"
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center">{val.billNo}</TableCell>
                                            <TableCell align="center">{val.paidAcceptBy}</TableCell>
                                            <TableCell align="center">{val.pDues}</TableCell>
                                            <TableCell align="center"><VisibilityIcon
                                                onClick={(event) => {
                                                    handleViewBills(val.billNo);
                                                }} /></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </React.Fragment>
        );
    };
    const renderMainDialogContent = () => {
        return (
            <React.Fragment>
                <div>
                    {
                        clearDues ? renderDialogDues() : renderDialogContent()
                    }
                </div></React.Fragment>
        );
    }

    const getSingleConfigs = (mConfigName) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&configName=${mConfigName}` + `&shopHash=${storeActiveShopHash.id}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //printLog("getSingleConfigs => ", cUrl);
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    if (mConfigName === CUSTLOYALITYPOINT) {
                        setLoyaityPoints(response.data === 'Yes')
                    } else if (mConfigName === PARTILBILLAUDIT) {
                        setPartialBillAudit(response.data === 'Yes');
                    } else if (mConfigName === ASSOCIATE_CONCEPT) {
                        setAssociateConcept(response.data === 'Yes');
                    }
                }
            }).catch((error) => {
                printDebugLog("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const fetchDues = (mId) => {
        let token = CART_INITIAL_STATE.apiToken;
        let shopHash = storeActiveShopHash.id;
        let custId = mId;
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETCUSTDUES + `?token=${token}`
            + `&shopHash=${shopHash}` + `&custId=${custId}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //printLog("getSingleConfigs => ", cUrl);
        setClearDuesLoader(true);
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                printDebugLog("fetchDues<=", response.data);
                setClearDuesLoader(false);
                if (response.data.status === SUCCESS) {
                    setSingleCustomerData({
                        ...customerSingleData,
                        pAudit: response.data.jcJson.pAudit,
                        tDues: response.data.jcJson.tDues,
                    })
                    setClearDues(true);
                } else {
                    setClearDues(false);
                }
            }).catch((error) => {
                setClearDuesLoader(false);
                setClearDues(false);
                printInfoLog("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const renderDialogContent = () => {
        return (
            <>
                <Paper elevation={10}
                    autoHeight
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: " #e9e9e9",
                        margin: "2px",
                        boxShadow: 1,
                        borderRadius: 5,
                        padding: 2,
                    }} >
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <ThreeDText
                                text="Customer Informartion"
                                display=""
                                justifyContent="left"
                                className="three-d-text-container"
                                alignItems="left" />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField
                                required
                                id="custName"
                                label="Enter Customer Name"
                                variant="filled"
                                size="small"
                                name="custName"
                                defaultValue={customerSingleData.custName}
                                onChange={handleInputChange}
                                fullWidth
                                error={formSubmitted && customerSingleData.custName === ""}
                                helperText={getErrorMessage('custName')}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField
                                id="emailId"
                                label="Enter EmailId"
                                variant="filled"
                                size="small"
                                name="emailId"
                                defaultValue={customerSingleData.emailId}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField
                                required
                                disabled={isEdit}
                                id="mob"
                                label="Enter Mobile Number"
                                variant="filled"
                                size="small"
                                name="mob"
                                inputProps={{ maxLength: 10 }}
                                value={customerSingleData.mob}
                                onChange={handleInputChange}
                                fullWidth
                                error={formSubmitted && customerSingleData.mob === ""}
                                helperText={getErrorMessage('mob')}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField
                                id="address"
                                label="Enter Address"
                                variant="filled"
                                size="small"
                                name="address"
                                defaultValue={customerSingleData.address}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField
                                id="custCompanyName"
                                label="Enter Company Details"
                                variant="filled"
                                size="small"
                                name="custCompanyName"
                                defaultValue={customerSingleData.custCompanyName}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField
                                id="custGstDetails"
                                label="Enter Gst Details"
                                variant="filled"
                                size="small"
                                name="custGstDetails"
                                defaultValue={customerSingleData.custGstDetails}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid></Grid>
                </Paper>
                {
                    assoicateConcept && <Paper elevation={10}
                        autoHeight
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: " #d4d4d4",
                            margin: "2px",
                            boxShadow: 1,
                            borderRadius: 5,
                            padding: 2,
                        }} >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ThreeDText
                                    text="Associate Informartion"
                                    display=""
                                    justifyContent="left"
                                    className="three-d-text-container"
                                    alignItems="left" />
                            </Grid>
                            <Grid item xs={12}>
                                <p style={{
                                    whiteSpace: "pre-wrap",
                                    overflowWrap: "break-word"
                                }}>
                                    <input
                                        name="assosiateAvailable"
                                        type="checkbox"
                                        checked={customerSingleData.assosiateAvailable}
                                        onChange={handleCheckboxChange}
                                    />
                                    Do you want to {!customerSingleData.assosiateAvailable ? "Enable" : "Disable"} Urban Pay?
                                </p>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <TextField
                                    id="associateId"
                                    label="Enter Assocaiate Id"
                                    variant="filled"
                                    size="small"
                                    name="associateId"
                                    disabled={!customerSingleData.assosiateAvailable}
                                    defaultValue={customerSingleData.associateId}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            {
                                !isNumberEmpty(customerSingleData.associateAmtUsed)
                                && <Grid item xs={12}>
                                    <Typography variant="h6" style={{ color: "#01333e", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                        {"Associate Money Used : " + customerSingleData.associateAmtUsed}
                                    </Typography>
                                </Grid>
                            }
                            
                        </Grid>
                    </Paper>
                }
                {
                    loyaityPoints && <Paper elevation={10}
                        autoHeight
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: " #9a9a9a",
                            margin: "2px",
                            boxShadow: 1,
                            borderRadius: 5,
                            padding: 2,
                        }} >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ThreeDText
                                    text="Loyality Informartion"
                                    display=""
                                    justifyContent="left"
                                    className="three-d-text-container"
                                    alignItems="left" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ color: "#01333e", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                    {"Loylity Points : " + customerSingleData.royalityPoint}
                                </Typography>
                            </Grid>
                            <Grid item md={12} xs={12} lg={12} sm={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Set Loylity Point Formula
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label="pointDenom"
                                        name="pointDenom"
                                        row
                                        value={customerSingleData.pointDenom.toString()}
                                        onChange={handleInputChange}
                                    >
                                        <FormControlLabel
                                            value={OfferType.No_Offer.toString()}
                                            control={<Radio />}
                                            label="No Formula"
                                        />
                                        <FormControlLabel
                                            value={OfferType.Flat.toString()}
                                            control={<Radio />}
                                            label="Flat"
                                        />
                                        <FormControlLabel
                                            value={OfferType.Percentage.toString()}
                                            control={<Radio />}
                                            label="Percentage"
                                        />
                                        <FormControlLabel
                                            value={OfferType.ONVALUE_FLAT.toString()}
                                            control={<Radio />}
                                            label="Flat Per Some Value"
                                        />
                                        <FormControlLabel
                                            value={OfferType.ONVALUE_PERC.toString()}
                                            control={<Radio />}
                                            label="Percentage Per Some Value"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {
                                (customerSingleData.pointDenom > 1 && loyaityPoints) &&
                                (
                                    <Grid item md={6} xs={12} lg={6} sm={6}>
                                        <TextField
                                            id="pointValue"
                                            name="pointValue"
                                            variant="filled"
                                            size="small"
                                            label="Enter Point Value"
                                            value={customerSingleData.pointValue.toString()}
                                            onChange={handleInputChange}
                                            fullWidth

                                        />
                                    </Grid>
                                )
                            }
                            {
                                (customerSingleData.pointDenom > 3 && loyaityPoints) &&
                                <Grid item md={6} xs={12} lg={6} sm={6}>
                                    <TextField
                                        id="pointDenomVal"
                                        name="pointDenomVal"
                                        variant="filled"
                                        size="small"
                                        label="Enter Point Value"
                                        value={customerSingleData.pointDenomVal.toString()}
                                        onChange={handleInputChange}
                                        fullWidth

                                    />
                                </Grid>
                            }
                            <Grid item md={6} xs={12} lg={6} sm={6}>
                                <TextField
                                    id="pointRealCostPer"
                                    name="pointRealCostPer"
                                    variant="filled"
                                    size="small"
                                    label="Enter Per Point Cost(Rs)"
                                    value={(parseFloat(customerSingleData.pointRealCostPer) > 0) ? customerSingleData.pointRealCostPer.toString() : CART_INITIAL_STATE.pointMethod.pointRealCostPer.toString()}
                                    onChange={handleInputChange}
                                    fullWidth

                                />
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {
                    (parseFloat(customerSingleData.tDues) > 0 && partilBillAvail && !clearDuesLoader) &&
                    <Grid item xs={12}>
                        <center>
                            <SnpButton variant="contained" mbgcolor="bRed" onClick={(e) => fetchDues(customerSingleData.id)} style={{ margin: "2px", float: "center" }}>
                                Check Dues
                            </SnpButton></center>
                    </Grid>
                }
                {
                    clearDuesLoader &&
                    <Grid item xs={12}>
                        <center>
                            <Loader /></center>
                    </Grid>
                }
                {
                    isFeatureAllowed(CUSTOMERAPP)
                    && <Grid xs={12}>
                        <center>
                            <FormControlLabel name="cartDeliveryAllowed"
                                checked={customerSingleData.cartDeliveryAllowed} control={<Checkbox />} onChange={() => {
                                    let pVal = customerSingleData.cartDeliveryAllowed;
                                    setSingleCustomerData({
                                        ...customerSingleData,
                                        cartDeliveryAllowed: !pVal,
                                    })
                                }} label={customerSingleData.cartDeliveryAllowed ? "Disable Cash on Delivery" : "Enable Cash on Delivery"} />
                        </center>
                    </Grid>
                }
            </>
        );
    };

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                {
                    !clearDues && <SnpButton variant="contained" color="primary" onClick={handleCustomerUpdate}>
                        {isEdit ? "Update" : "Add"}
                    </SnpButton>
                }

                <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
                    Dismiss
                </DismissButton>
            </React.Fragment>
        );
    };

    const handleDialogClose = () => {
        // console.log("handleDialogClose");
        setSingleCustomerData(INITIAL_CUSTOMER);
        setClearDues(false);
        setEdit(false);
        setShowDialog(false);
        setFormSubmitted(false);
    };

    const getErrorMessage = (field) => {
        if (!formSubmitted) {
            return;
        } else if (!customerSingleData[field]) {
            return 'Field is required';
        }
    }

    const getColumns = () => {
        if (assoicateConcept) return columnsAssociate;
        if (loyaityPoints) return columns;
        return columnsBasic;
    };

    return (
        <React.Fragment>
            <Grid container spacing={1} display="flex"
                alignItems="center">
                <Grid item md={12} sm={12} xs={12} lg={12} style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ padding: 10 }}>
                        <EnterButton
                            variant="contained"
                            color="primary"
                            style={{ float: "right" }}
                            onClick={(event) => {
                                setShowDialog(true);
                            }}
                        >
                            Add New Membership
                        </EnterButton>
                    </div>
                </Grid><Grid item md={12} sm={12} xs={12} lg={12}>
                    <CustomDataGrid
                        getRowId={row => row.id}
                        rows={customerData}
                        columns={getColumns()}
                        loading={!isDataLoaded}
                    />
                </Grid>
            </Grid>
            <DialogBox
                showDialog={showDialog}
                title={clearDues ? "Dues Section" : isEdit ? "Update Membership" : "Add New Membership"}
                onClose={handleDialogClose}
                dialogContent={renderMainDialogContent()}
                dialogActions={renderDialogAction()}
            />
            {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({ ...videoModel, show: false })} />}
            {videoModel.path && <SingleFab onClick={(e) => setVideoModel({ ...videoModel, show: true })} />}
        </React.Fragment>
    );
}
