import React, { useState, useEffect, useRef, useCallback } from 'react';

import { FoodType, isEmpty, printDebugLog, Spicy } from '../../../constants/constant';
import spiceLevel3Logo from '../../../assets/chili-pepper.png';
import spiceLevel1Logo from '../../../assets/chili.png';
import { Grid, Paper, Tooltip } from '@mui/material';
import '../BillingModule.css';
import { throttle } from 'lodash';

const ProductGrid = ({ filteredCatgProductData, handleItemData }) => {
    const [visibleItems, setVisibleItems] = useState(15); // Initially render 10 items
    const [isFetching, setIsFetching] = useState(false);
    const scrollContainerRef = useRef(null); // Ref for the scrolling div

    const handleScroll = useCallback(() => {
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer;

            // Check if user is near the bottom of the div
            if (scrollTop + clientHeight >= scrollHeight - 100 && !isFetching) {
                setIsFetching(true);
            }
        }
    }, [isFetching]);

    useEffect(() => {
        //printDebugLog("ProductGrid",filteredCatgProductData)
        // Attach scroll listener
        const scrollId = scrollContainerRef.current;
        printDebugLog("ScrollId", scrollId, scrollId.scrollHeight, scrollId.scrollTop, scrollId.clientHeight);
        if (scrollId) {
            scrollId.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollId) {
                printDebugLog("ScrollId", "removing");
                scrollId.removeEventListener('scroll', handleScroll);
            }
        } // Cleanup listener
    }, [handleScroll]);

    useEffect(() => {
        // Load more items when isFetching is true
        if (isFetching && visibleItems < filteredCatgProductData.length) {
            setTimeout(() => {
                setVisibleItems((prev) => Math.min(prev + 15, filteredCatgProductData.length)); // Load next 10 items
                setIsFetching(false);
            }, 500); // Optional: simulate network delay
        }
    }, [isFetching, visibleItems, filteredCatgProductData.length]);

    const getProductName = (data) => {
        let name = data.name;
        if (isEmpty(data.price.name))
            name = name + "(₹" + data.price.sAmt + ")";
        else
            name = name + "(" + data.price.name + "-₹" + data.price.sAmt + ")";
        return name;
    };

    const getLiveStockCount = (data) => {
        if (!isEmpty(data.liveStockCount))
            return data.liveStockCount + " avail.";
        return '';
    };

    const getItemSpicyLevel = (product) => {
        return (
            <>
                {product.spicy === Spicy.LESS_SPICY &&
                    <img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel1Logo} alt="Less Spice" />
                }
                {product.spicy === Spicy.MEDIUM_SPICY &&
                    <><img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel1Logo} alt="Medium Spice" /> <img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel1Logo} alt="Medium Spice" /></>
                }
                {product.spicy === Spicy.EXTRA_SPICY &&
                    <img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel3Logo} alt="Extra Spice" />
                }
            </>
        )
    };

    const throttledHandleItemData = useCallback(
        throttle((data) => handleItemData(data), 300), [handleItemData]);

      const productPaperStyles = (data) => ({
        borderRadius: 4,
        margin: "2px",
        borderLeft: data.foodType === FoodType.NON_VEG 
            ? "5px solid #8a1b1b" 
            : data.foodType === FoodType.EGG 
                ? "5px solid #e79a53" 
                : "5px solid #0b3104",
        backgroundColor: "rgba(233, 231, 231, 0.849)",
    });

    const renderProductData = (data) => (
        <Grid
            item
            md={filteredCatgProductData.length <= 3 ? 6 : 3}
            sm={filteredCatgProductData.length <= 4 ? 6 : 4}
            lg={filteredCatgProductData.length <= 3 ? 6 : 3}
            xs={6}
            key={data.id}
        >
            <Tooltip title={data.ingredients}>
                <Paper
                    elevation={0}
                    onClick={() => throttledHandleItemData(data)}
                    className="restaurant-product-data-container"
                    sx={productPaperStyles(data)}
                >
                    <center>
                        <p
                            style={{
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word',
                                fontSize: '14px',
                            }}
                        >
                            {getProductName(data)}
                            {getItemSpicyLevel(data)}
                            {getLiveStockCount(data)}
                        </p>
                    </center>
                </Paper>
            </Tooltip>
        </Grid>
    );
    
    return (
        <div ref={scrollContainerRef}
            style={{
                height: '300px', // Adjust this to the height of your scrollable container
                overflowY: 'auto', // Enable vertical scrolling
                // Optional: for visual debugging
            }}>

            <Grid container spacing={2}>
                {filteredCatgProductData.length && filteredCatgProductData.slice(0, visibleItems).map(renderProductData)}
                {isFetching && visibleItems < filteredCatgProductData.length && (
                    <Grid item xs={12}>
                        <center>
                            <p>Loading ...</p>
                        </center>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ProductGrid;
