import SnpComponent from "../global/SnpComponent";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./BillConnector.css";
import { CART_INITIAL_STATE, GET_TODAYBALANCE_DATA, GETBILLBYREGEX, GETBILLTYPEDETAILS, getPreUrlAccordingToCart, GETSINGLECONFIGS, GETVIDEOPATH, printDebugLog, SUCCESS, TODAYBALVIEWINDASHBOARD } from "../../constants/constant";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CompressIcon from '@mui/icons-material/Compress';
import { Autocomplete, Box, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { snpAxios } from "../global/api";
import axios from "axios";
import { SCREEN_DASHBOARD_KIRANA } from "../../constants/VideoConstant";
import YouTubeVideo from "../global/YouTubeVideo";
import SingleFab from "../global/fab/SingleFab";
import { useNavigate } from "react-router-dom";

export default function BillConnector() {

    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const navigate = useNavigate();

    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
        snackColor: '',
        snackVertical: 'bottom',
        snackHorizontal: 'left',
    };

    const INITIAL_TODAYS_BAL_DATA = {
        balance: 0.0,
        expense: 0.0,
        dues: 0.0,
    };
    const INITIAL_BILL_TYPE = {
        total_bill: 0.0,
        yesterday_bill: 0.0,
        todays_bill: 0.0,
        waste_total_count: 0.0,
        return_total_count: 0.0,
        online_total_count: 0.0,
        online_paid_count: 0.0,
    };
    const [todayBalData, setTodayBalanceData] = useState(INITIAL_TODAYS_BAL_DATA);
    const [listAllBill, setListAllBill] = useState([]);
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [loader, setLoader] = useState(false);
    const debounceFetch = useRef(null);
    const [todaysBalSheetAlwd, setTodayBalSheetAllowed] = useState(false);
    const [mAccType, setAccType] = useState(CART_INITIAL_STATE.defBalanceType);
    const [listAcc, setListAcc] = useState([]);
    const [videoModel, setVideoModel] = useState({ show: false, path: "" });
    const [billTypeDetails, setBillTypeDetails] = useState(INITIAL_BILL_TYPE);

    const items = useMemo(() => [{
        imgSrc: "./create-bill.png",
        name: "Create Bill",
        background: "rgb(123, 211, 184)",
        onClick: () => navigate("/dashboard/billentry", { state: { tableProps:{} ,sBillNo: "", billType: -1 } }),
        prices: ["*Today's Count "+billTypeDetails.todays_bill]
    },
    {
        imgSrc: "./return_waste.png",
        name: "Return Billing/Waste Billing",
        background: "rgb(247, 188, 186)",
        onClick: () => navigate("/dashboard/returnwastebill"),
        prices: ["*Today's Waste B. "+billTypeDetails.waste_total_count,
                "*Today's Return B. "+billTypeDetails.return_total_count]
    },
    {
        imgSrc: "./show_bill.png",
        name: "Show Bills",
        background: "#c5ecbc",
        onClick: () => navigate("/dashboard/showbills"),
        prices: ["*Yesterday "+billTypeDetails.yesterday_bill,
            "*Total "+billTypeDetails.total_bill]
    }], [billTypeDetails]);

    const balItems = useMemo(() => [
        {
            icon: <ArrowDropUpIcon />,
            background: "#268a1b",
            data: todayBalData.balance,
            title: "Today's Balance"
        },
        {
            icon: <ArrowDropDownIcon />,
            background: "#8a221b",
            data: todayBalData.expense,
            title: "Today's Expense"
        },
        {
            icon: <CompressIcon />,
            background: "#8a811b",
            data: todayBalData.dues,
            title: "Today's Dues"
        },
    ], [todayBalData]);


    const fetchBill = useCallback(async (regexStr) => {
        try {
            const fetchBillUrl = `${storeApiUrl.BACKEND_BASE_URL}${GETBILLBYREGEX}?adminId=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}&regexStr=${regexStr}`;
            const response = await snpAxios.post(fetchBillUrl, {});
            if (response.data.status === SUCCESS) {
                setListAllBill(response.data.jcJson.listBills || []);
            }
        } catch (error) {
            console.error("Error fetching bills: ", error);
        }
    }, [storeApiUrl, storeActiveShopHash]);

    const getBalData = async (accType) => {
        let custUrl =
            storeApiUrl.BACKEND_BASE_URL + GET_TODAYBALANCE_DATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                accountType: accType,
            },
        };
        //   console.log("getBalData => ",data);
        snpAxios
            .post(custUrl, data)
            .then((response) => {
                //console.log("getBalData => ", response.data);
                //setAccDialogLoader(false);
                if (response.data.status === SUCCESS) {
                    setTodayBalanceData({
                        ...todayBalData,
                        balance: response.data.jcJson.balance,
                        expense: response.data.jcJson.expense,
                        dues: response.data.jcJson.dues,
                    })
                    setListAcc(response.data.jcJson.listAcc);
                    setAccType(accType);
                }

            })
            .catch((error) => {
                //setAccDialogLoader(false);
                console.log("getBalData Error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getYoutubeVideo = useCallback(async (videoName, lang) => {
        try {
            const cUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GETVIDEOPATH}?adminId=${CART_INITIAL_STATE.apiToken}&name=${videoName}&lang=${lang}`;
            const response = await axios.get(cUrl, {});
            if (response.data !== '') {
                setVideoModel({ ...videoModel, path: response.data });
            } else
                setVideoModel({ ...videoModel, path: '' });

        } catch (error) {
            setVideoModel({ ...videoModel, path: '' });
        }
    }, [storeApiUrl, storeActiveShopHash]);

    const getSingleConfigs = async (mConfigName) => {        
        try {
            const cUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GETSINGLECONFIGS}?adminId=${CART_INITIAL_STATE.apiToken}&configName=${mConfigName}&shopHash=${storeActiveShopHash.id}`;
            const response = await axios.get(cUrl, {});
            const configValue = response.data;
    
            if (configValue) {
                switch (mConfigName) {
 
                    case TODAYBALVIEWINDASHBOARD:
                        setTodayBalSheetAllowed(configValue === 'Yes');
                        if (configValue === 'Yes') {
                            getBalData(mAccType); // Assuming `mAccType` is defined elsewhere
                        }
                        break;
    
                    default:
                        console.warn(`Unhandled config name: ${mConfigName}`);
                }
            }
        } catch (error) {
            console.error("Error fetching configuration: ", error);
            // Optionally redirect or handle error UI
            // window.location.href = "#/error";
        }
    };

    const getBillTypeDetails = async () => {
        try {
            setLoader(true);
            const mUrl = `${storeApiUrl.BACKEND_BASE_URL}${GETBILLTYPEDETAILS}?token=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}`;
            const response = await axios.get(mUrl,{});
            setLoader(false);
            printDebugLog("getBillTypeDetails responses => ", response.data);
            if (response.data.status === SUCCESS) {
                setBillTypeDetails({
                    ...billTypeDetails, ...response.data.jcJson,
                })
            }
        } catch(error) {
            setLoader(false);
            console.error("getBillTypeDetails error : ", error);
        }
    }

    

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const handleSelectBill = useCallback((ev, option) => {
        if (option?.billNo) {
            navigate("/dashboard/billentry", { state: { tableProps:{} ,sBillNo: option.billNo, billType: -1 } });
        }
    }, []);

    const handleBillSearch = useCallback((event) => {
        const value = event.target.value;
        if (debounceFetch.current) {
            clearTimeout(debounceFetch.current);
        }
        if (value.length >= 4) {
            debounceFetch.current = setTimeout(() => {
                fetchBill(value);
            }, 300);
        }
    }, [fetchBill]);

    const getSearchSelectDetails = (option) => {
        let data = "";
        if (option) {
            data = option.billNo;
            if (option.custMob)
                data = data + "(" + option.custMob + ")";
        }
        return data;
    };

    const renderBalContent = useMemo(() => {
        return (
            <div className="bill-container">
                {balItems.map((item, index) => (
                    <div
                        key={index}
                        className="bal-item"
                        style={{ background: item.background }}
                        title={item.title}
                    >

                        <p style={{
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word',
                            color: "white",
                            fontSize: "19px"
                        }}>{item.icon}₹{item.data}
                        </p>
                    </div>
                ))}
            </div>
        );
    }, [balItems]);

    const renderItemsContent = useMemo(() => {
        return (
            <div className="bill-container">
                {items.map((item, index) => (
                    <div
                        key={index}
                        className="item"
                        style={{ background: item.background }}
                        onClick={item.onClick}
                    >
                        <img src={item.imgSrc} alt={item.name} className="item-img" />
                        <p
                            style={{
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                                fontWeight: "bold",
                                fontSize: "16px",
                            }}
                        >
                            {item.name}
                        </p>
                        {
                            item.prices.map((price, index) => (
                                <p key={index} style={{
                                    whiteSpace: 'pre-wrap',
                                    overflowWrap: 'break-word',
                                    fontSize: "18px"
                                }}>{price}
                                </p>
                            ))
                        }
                    </div>
                ))}
            </div>
        );
    }, [items]);

    const renderSearchBill = useMemo(() => {
        return (
            <div className="bill-container">
                <div className="autocomplete-item">
                    <Autocomplete id="search-select"
                        options={listAllBill}
                        autoHighlight
                        variant="filled"
                        fullWidth
                        onChange={handleSelectBill}
                        onBlur={(e) => setListAllBill([])}
                        getOptionLabel={(option) => getSearchSelectDetails(option)}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                {...props}
                            >
                                {getSearchSelectDetails(option)}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search Bill or Mobile Number"
                                onChange={handleBillSearch}
                                inputProps={{
                                    ...params.inputProps,
                                }}
                            />
                        )}
                    />
                </div>
            </div>
        )
    }, [listAllBill,handleSelectBill,getSearchSelectDetails,handleBillSearch]);

    const renderSnpContent = () => {
        return (
            <div>
                {todaysBalSheetAlwd && renderBalContent}
                {renderSearchBill}
                {renderItemsContent}
                {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({ ...videoModel, show: false })} />}
                {videoModel.path && <SingleFab onClick={(e) => setVideoModel({ ...videoModel, show: true })} />}
            </div>
        )
    };

    useEffect(()=> {
        getYoutubeVideo(SCREEN_DASHBOARD_KIRANA, 'hi');
        getSingleConfigs(TODAYBALVIEWINDASHBOARD);
        getBillTypeDetails();
    }, [storeActiveShopHash, storeApiUrl])

    return (
        <SnpComponent
            content={renderSnpContent()}
            isLoader={loader}
            showDialog={snpComponent.showDialog}
            title={snpComponent.title}
            subTitle={snpComponent.subTitle}
            negativeAction={snpComponent.negativeAction}
            positiveReq={snpComponent.positiveReq}
            positiveAction={snpComponent.positiveAction}
            negativeReq={snpComponent.negativeReq}
            handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
            iconReq={snpComponent.iconReq}
            onSnackClose={handleSnackClose}
            cancelExtra={snpComponent.cancelExtra}
            onCancel={snpComponent.handleSnpCancel}
            snackBarOpen={snpComponent.snackBarOpen}
            snackBarMsg={snpComponent.snackBarMsg}
            outSideClickDismiss={snpComponent.outSideClickDismiss}
            handleSubmit={snpComponent.handleSnpSubmit}
            gui={snpComponent.gui}
            // bgColor='#01333e'
            snackColor={snpComponent.snackColor}
            snackVertical={snpComponent.snackVertical}
            snackHorizontal={snpComponent.snackHorizontal}
        />
    );
}