import React, { memo } from "react";
import { SearchOption } from "../../../constants/constant";
import { Autocomplete, Box, FormControl, FormControlLabel, FormLabel, Grid, LinearProgress, Radio, RadioGroup, TextField } from "@mui/material";

const RenderSearchChoice = memo(({ defaultId, onSearch, onDefaultIdChange, onTextChange, loader ,listStock , handleProductSearch, handleUpdateStockDetails }) => {
    const optionList = [
        { id: SearchOption.SEARCHBYBARC, optName: "Bar Code" },
        { id: SearchOption.SEARCHBYCUSTOMBARCODE, optName: "Custom Bar Code" },
        { id: SearchOption.SEARCHBYPRODN, optName: "Product Name" }
    ];
    return (
        <>
            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Search By</FormLabel>
                    <RadioGroup
                        aria-label="searchBy"
                        name="searchBy"
                        value={defaultId}
                        onChange={(e) => onDefaultIdChange(Number(e.target.value))}
                        row={true}
                    >
                        {optionList.map((option) => (
                            <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={<Radio />}
                                label={option.optName}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                {
                    defaultId === SearchOption.SEARCHBYPRODN ?
                        <Autocomplete id="search-select"
                            options={listStock}
                            autoHighlight
                            variant="filled"
                            size="small"
                            fullWidth
                            onChange={(e,opt) => handleUpdateStockDetails(opt)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.details}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    {option.details}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Product"
                                    onChange={(e)=>handleProductSearch(e.target.value)}
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        /> :
                        <TextField
                            required
                            id="inputField"
                            name="inputField"
                            variant="filled"
                            size="small"
                            label="Enter Details"
                            fullWidth
                            autoFocus // Ensure the TextField is always focused
                            InputLabelProps={{
                                style: { fontSize: '12px', fontWeight: 'bold' }
                            }}
                            onChange={(e) => onTextChange(e.target.value)} // Update `searchText` on input change
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    onSearch(); // Trigger the search
                                }
                            }}
                        />

                }
            </Grid>
            {loader && <LinearProgress color="primary" />}
        </>
    );
});

export default RenderSearchChoice;