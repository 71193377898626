import React, { useLayoutEffect, useState } from "react";
import { Grid, TextField, Box, Autocomplete, Checkbox } from "@mui/material";
//import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GET_CATEOGORY_DATA,
  SET_CATEOGORY_DATA,
  getPreUrlAccordingToCart,
  SUCCESS,
  ItemStatus,
  CART_INITIAL_STATE, isRoleAllowed, INVENTORYCREATE, CATGCREATION, GETSINGLECONFIGS, ECARTAPP, printInfoLog, THEMEMGMT, GETFOODITEMSUGGESTION, printDebugLog, isEmpty, isFeatureAllowed,
  GETVIDEOPATH
} from "../constants/constant";
import DialogBox from "../components/global/DialogBox";
import {
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
} from "@mui/material";
import CustomDataGrid from "./global/CustomDataGrid";
import { useSelector } from "react-redux";
import { snpAxios } from "./global/api";
import axios from "axios";
import { SCREEN_CATEOGORY } from "../constants/VideoConstant";
import YouTubeVideo from "./global/YouTubeVideo";
import SingleFab from "./global/fab/SingleFab";
import DismissButton from "./global/DismissButton";
import SnpButton from "./global/SnpButton";
import utilities from "../utilities/utilities";
import EnterButton from "./global/EnterButton";

export default function Category({ showDialogBoxWithPaper }) {
  const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [foodItemData, setFoodItemData] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [suggestedFoodItem, setSuggestedFoodItem] = useState([])
  const [suggestedFoodItemDeleted, setSuggestedFoodItemDeleted] = useState([])
  const [suggestionAvail,setSuggAvail] = useState(false);
  const [inputValue, setInputValue] = useState('');
  //Ecart App Feature available
  const [ecartApp,setEcartApp] = useState(false);
  const [categorySingleData, setSingleCategoryData] = useState({
    catgStatus: ItemStatus.ACTIVE,
    id: "",
    catgName: "",
    cartVisible:true,

  });
  const [videoModel, setVideoModel] = useState({show:false,path:""});

  const handleQuickItemsSearch = (event, mode) => {
    const value = event.target.value;
    //console.log("handleQuickItemsSearch",value)
    if (value.length >= 3) {
      getAllFoodItem(value);
    }
};

const handleInputChangeValue = (event) => {
    if (event) {
        //printDebugLog("handleInputChangeValue",event.target.value)
        setInputValue(event.target.value);
    }
}

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    //console.log("handleInputChange",name , value);
    if (name === 'catgStatus') {
      let num = Number(value);
      setSingleCategoryData({
        ...categorySingleData,
        catgStatus: num,
        cartVisible: (num === ItemStatus.ACTIVE) ? true : false,
      });
    } else
      setSingleCategoryData({
        ...categorySingleData,
        [name]: value,
      });
  };

  const columns = [
    { field: "catgName", headerName: "Category Name", width: 220 },
    {
      field: "catgStatusStr",
      headerName: "Category Status",
      width: 190,valueGetter: (params) => params.row.catgStatusStr,renderCell: (params) => (
        <span style={{  color: params.value === 'Active' ? '#0f633b' : "#7a1904" }}>{params.value}</span>
    ),},
    {
      field: "view",
      headerName: "View",
      width: 70,
      renderCell: (cellValues) => {
        return (
          <VisibilityIcon
            onClick={(event) => {
              if (isRoleAllowed(CATGCREATION)) {
                handleCellClick(event, cellValues);
              } else 
                showDialogBoxWithPaper("You Are not allowed to Add/Update Cateogory.Contact the Owner");
            }}
          ></VisibilityIcon>
        );
      },
    },
  ];

  const handleDialogClose = () => {
    printInfoLog("handleDialogClose","");
    // let suggestionData = "";
    // if (!isEmpty(suggestedFoodItem))
    //   suggestionData = suggestedFoodItem.map(item => { return  item.foodItemId});
    // printLog("handleDialogClose",suggestionData);
    // printLog("handleDialogClose-suggestedFoodItemDeleted",suggestedFoodItemDeleted);
    setSingleCategoryData({
      ...categorySingleData,
      catgStatus: ItemStatus.ACTIVE,
      id: "",
      catgName: "",
      cartVisible:true,
    });
    setEdit(false);
    setShowDialog(false);
    setSuggestedFoodItem([]);
    setSuggAvail(false);
    setSuggestedFoodItemDeleted([]);
    setFormSubmitted(false);
    //setSuggestedFoodItem([]);
  };

  const handleCellClick = (param, event) => {
    setSingleCategoryData({
      ...categorySingleData,
      catgStatus: event.row.catgStatus,
      id: event.row.id,
      catgName: event.row.catgName,
      cartVisible:event.row.cartVisible,
    });
    if (event.row.listItemSuggestion.length > 0) {
      setSuggAvail(true);
      setSuggestedFoodItem(event.row.listItemSuggestion);
    }
    //console.log("setSingleCategoryData : ",categorySingleData);
    setEdit(true);
    setShowDialog(true);
  };

  const handleSubmitCatg = async () => {
    setFormSubmitted(true);
    if (!isValid()) {
      return;
    }
    setDataLoaded(false);
    let suggestionData = "";
    if (!isEmpty(suggestedFoodItem))
      suggestionData = suggestedFoodItem.map(item => { return  item.foodItemId});
    let catgUrl =
      storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + SET_CATEOGORY_DATA;
    let filledData = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash:storeActiveShopHash.id,
        catgName: categorySingleData.catgName,
        id: categorySingleData.id,
        catgStatus: parseInt(categorySingleData.catgStatus),
        cartType: storeCartType,
        cartVisible:categorySingleData.cartVisible,
        listItemSuggestion:suggestionData,
        delListItemSuggestion:suggestedFoodItemDeleted,
        //suggestedFoodItem        -- This will used to add/update suggested item in category we can send either id or complete object as per the API
      },
    };
    //console.log("handleSubmitCatg => ",filledData);
    handleDialogClose();
    
    snpAxios
            .post(catgUrl, filledData)
      .then((response) => {
        printDebugLog("responses => ", response.data);
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          getCateogoryData();
        }
      })
      .catch((error) => {
        printInfoLog("error : ", error);
        setDataLoaded(true);
        //window.location.href = "#/error";
      });
  };

  const getCateogoryData = async () => {
    let catgUrl =
      storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_CATEOGORY_DATA;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash:storeActiveShopHash.id,
      },
    };
    //   console.log("verifyCredentials => ",data);
    snpAxios
      .post(catgUrl, data)
      .then((response) => {
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          setCategoryData(response.data.jcJson.value_2);
        }
      })
      .catch((error) => {
        setDataLoaded(true);
        printInfoLog("Error : ", error);
        //window.location.href = "#/error";
      });
  };

  const getAllFoodItem = async (regextStr) => {
    if (isEmpty(regextStr))
        return;
    let encodedHash = utilities.encode(storeActiveShopHash.id);
    let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType)+ GETFOODITEMSUGGESTION + "?s=" + encodedHash + "&q=" + regextStr;
    let data = {};
    axios.get(
        fetchBillUrl,
        data
    )
        .then((response) => {
            printDebugLog("getAllFoodItem", response.data);
            if (response.data.status === SUCCESS) {
                setFoodItemData(response.data.jcJson.listItemSuggestion);
            }
        })
        .catch((error) => {
            printDebugLog("Error : ", error);
        });
};

  const getErrorMessage = (field) => {
    if (!formSubmitted) {
      return;
    } else if (!categorySingleData[field]) {
      return 'Field is required';
    }
  }
  const getYoutubeVideo = (videoName,lang) => {
    let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETVIDEOPATH + `?adminId=${CART_INITIAL_STATE.apiToken}&name=${videoName}&lang=${lang}`;
    let data = {};
    axios.get(
        cUrl,
        data
    )
        .then((response) => {
            //printLog("getSingleConfigs<=",response.data);
            if (response.data !== '') {
                setVideoModel({...videoModel,path : response.data});
            } else 
                setVideoModel({...videoModel,path : ''});
        }).catch((error) => {
            //printLog("error : ", error);
            //window.location.href = "#/error";
            setVideoModel({...videoModel,path : ''});
        });
};

  const getSingleConfigs = (mConfigName) => {
    let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}&configName=${mConfigName}&shopHash=${storeActiveShopHash.id}`;
    let data = {};
    //const urlFormattedString = encodeURIComponent(cUrl);
    //printLog("getSingleConfigs => ", cUrl);
    axios.get(
        cUrl,
        data
    )
        .then((response) => {
            //printLog("getSingleConfigs<=",response.data);
            if (response.data !== '') {
                if (mConfigName === ECARTAPP) {
                    setEcartApp(response.data === 'Yes');
                }
            }
        }).catch((error) => {
            printInfoLog("error : ", error);
            //window.location.href = "#/error";
        });
};

  useLayoutEffect(() => {
    //  console.log('Printing Login Details : ',CART_INITIAL_STATE);
    getYoutubeVideo(SCREEN_CATEOGORY,'hi');
    if (isFeatureAllowed(THEMEMGMT)) {
      getSingleConfigs(ECARTAPP);
      getCateogoryData();
    } else  
      getCateogoryData();
  }, [storeCartType,storeApiUrl]);

  const renderDialogContent = () => {
    return (
      <React.Fragment>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <TextField
              required
              id="cateog-Name"
              label="Enter Category Name"
              name="catgName"
              defaultValue={categorySingleData.catgName}
              onChange={handleInputChange}
              error={formSubmitted && !categorySingleData.catgName}
              helperText={getErrorMessage('catgName')}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Enter Category Status
              </FormLabel>
              <RadioGroup
                row={false}
                aria-label="catgStatus"
                name="catgStatus"
                value={Number(categorySingleData.catgStatus)}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value={Number(ItemStatus.ACTIVE)}
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value={Number(ItemStatus.INACTIVE)}
                  control={<Radio />}
                  label="InActive"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {
             ecartApp && <Grid md={5} xs={12} sm={6} lg={4}>
             <center>
               <FormControlLabel name="cartVisible"
                 checked={categorySingleData.cartVisible} disabled={(categorySingleData.catgStatus === ItemStatus.ACTIVE) ? false : true} control={<Checkbox />} onChange={() => {
                   let pVal = !categorySingleData.cartVisible;
                   setSingleCategoryData({
                     ...categorySingleData,
                     cartVisible : pVal,
                   })
                 }} label={<><p style={{
                  whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
              }}>Cateogory visible to end customer</p></>} />
             </center>
           </Grid>
          }
          {
            ecartApp && <Grid xs={12} md={7} sm={6} lg={8}>
              <Autocomplete
                multiple
                disabled={(categorySingleData.catgStatus === ItemStatus.ACTIVE) ? false : true}
                defaultValue={suggestedFoodItem}
                options={foodItemData}
                limitTags={2}
                sx={{ width: 300 }}
                onBlur={(e) => setFoodItemData([])}
                onInputChange={handleInputChangeValue}
                getOptionLabel={(option) => option.foodItemName}
                inputValue={inputValue}
                onChange={(event, newValue, reason, detail) => {
                  printDebugLog("Autocomplete", newValue, reason);
                  setInputValue('');
                  if (suggestionAvail) {
                    if (reason === 'removeOption') {
                      const updatedItems = [...suggestedFoodItemDeleted, detail.option.foodItemId];
                      setSuggestedFoodItemDeleted(updatedItems);
                    } else if (reason === 'selectOption') {
                      if (suggestedFoodItemDeleted.length > 0) {
                        const idToDelete = detail.option.foodItemId;
                        const indexToDelete = suggestedFoodItemDeleted.findIndex(item => item === idToDelete);
                        // If item is found, create a new array without it
                        if (indexToDelete !== -1) {
                          const updatedItems = [...suggestedFoodItemDeleted.slice(0, indexToDelete), ...suggestedFoodItemDeleted.slice(indexToDelete + 1)];
                          setSuggestedFoodItemDeleted(updatedItems);
                        }
                      }
                    } else if (reason === 'clear') {
                      suggestedFoodItem.forEach(element => {
                        const updatedItems = [...suggestedFoodItemDeleted, element.foodItemId];
                        setSuggestedFoodItemDeleted(updatedItems);
                      });
                    }
                  }
                  setSuggestedFoodItem(newValue);
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.foodItemName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Add Item Suggestion"
                    placeholder="Search"
                    onChange={handleQuickItemsSearch}
                  />
                )}
              />
            </Grid>
          }
          
        </Grid>
      </React.Fragment>
    );
  };

  const renderDialogAction = () => {
    return (
      <React.Fragment>
        {
          <SnpButton variant="contained" color="primary" onClick={handleSubmitCatg}>
            {!isEdit ? "Add" : "Update"}
          </SnpButton>
        }
        <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
          Dismiss
        </DismissButton>
      </React.Fragment>
    );
  };

  

  const isValid = () => {
    let isCategoryDataValid = true;

    if (!categorySingleData.catgName) {
      isCategoryDataValid = false;
    }

    return isCategoryDataValid;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {/* <Grid item xs={12}>
        </Grid> */}
        <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ padding: 10 }}>
            <EnterButton
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              onClick={(event) => {
                if (isRoleAllowed(INVENTORYCREATE)) {
                  setShowDialog(true);
                } else {
                  showDialogBoxWithPaper("You Are not allowed to Add Cateogory.Contact the Owner");
                }
              }}
            >
              Add New Cateogory
            </EnterButton>
          </div>

          <CustomDataGrid
              getRowId={row => row.id}
              rows={categoryData}
              columns={columns}
              loading={!isDataLoaded}
            />
        </Grid>
      </Grid>
      <DialogBox
        showDialog={showDialog}
        title={isEdit ? "View Cateogory" : "Add New Cateogory"}
        onClose={handleDialogClose}
        dialogContent={renderDialogContent()}
        dialogActions={renderDialogAction()}
      ></DialogBox>
      {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({...videoModel,show:false})}/> }
      {videoModel.path && <SingleFab onClick={(e)=> setVideoModel({...videoModel,show:true})}/> }
    </React.Fragment>
  );
}
