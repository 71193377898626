import { useCallback, useMemo, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import CustomDataGrid from "../global/CustomDataGrid";
import RenderSearchChoice from "./mart/RenderSearchChoice";
import { CART_INITIAL_STATE, getPreUrlAccordingToCart, GETPRODDETAILS, printDebugLog, SearchOption, SUCCESS } from "../../constants/constant";
import ThreeDText from "../global/ThreeDText";
import axios from "axios";


export default function MartBilling() {


    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: false,
        iconContent: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
        snackVertical: "",
        snackHorizontal: "",
        snackColor: "",
    };

    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [snpLoader, setSnpLoader] = useState(false);
    const [columnList, setColumnList] = useState([]);
    const [defaultId, setDefaultId] = useState(SearchOption.SEARCHBYBARC);
    const [searchText, setSearchText] = useState("");
    const [productSearchLoader, setProductSearchLoader] = useState(false);
    const [listStock, setListStock] = useState([]);

    const columns = [
        {
            field: 'item', headerName: 'Item', width: 150
        },
    ];

    const getProductDetails = async (stock) => {
        try {
            setProductSearchLoader(true);
            const productDetails = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GETPRODDETAILS}?adminId=${CART_INITIAL_STATE.apiToken}&stock=${stock}&shopHash=${storeActiveShopHash.id}`;
            const response = await axios.get(productDetails, {});
            if (response.data.status === SUCCESS) {
                setListStock(response.data.jcJson.value_2);
            }
            setProductSearchLoader(false);
        } catch (error) {
            console.error("getProductDetails error : ", error);
            setProductSearchLoader(false);
        }
    };

    const handleSearch = useCallback(() => {
        printDebugLog("handleSearch:", { defaultId, searchText });
        setSnpComponent({
            ...snpComponent,
            snackBarMsg: "Barcode : " + searchText,
            snackBarOpen: true,
        })
    }, [defaultId, searchText]);


    const handleProductSearch = (value) => {
        printDebugLog("handleProductSearch", value);
        if (value.length >= 3) {
            getProductDetails(value);
        }
    }

    const handleUpdateStockDetails = (option) => {
        if (option != null) {
            setListStock([]);
            printDebugLog("handleUpdateStockDetails:", option);
        }
    };

    const dataGridComponent = useMemo(() => {
        return (
            <CustomDataGrid
                getRowId={(row) => row.prdId}
                rows={columnList}
                columns={columns}
            />
        );
    }, [columnList]);

    const searchComponent = useMemo(() => {
        return (
            <RenderSearchChoice
                defaultId={defaultId}
                onSearch={handleSearch}
                onDefaultIdChange={setDefaultId}
                onTextChange={setSearchText}
                loader={productSearchLoader}
                listStock={listStock}
                handleProductSearch={handleProductSearch}
                handleUpdateStockDetails={handleUpdateStockDetails}
            />
        )
    }, [defaultId, handleSearch, productSearchLoader, listStock]);

    const renderSnpContent = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper elevation={0}
                        autoHeight
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#dbdcff",
                            boxShadow: 1,
                            borderRadius: 5,
                            padding: 1,
                        }} >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ThreeDText
                                    text="Add/Update item here"
                                    display=""
                                    className="three-d-text-container"
                                    justifyContent="left"
                                    alignItems="left" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                {searchComponent}
                            </Grid>
                            <Grid item xs={12} sm={12} md={10} lg={10}>
                                {dataGridComponent}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} sm={6} lg={4}>
                    <Paper elevation={0}
                        autoHeight
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#f2e0cc",
                            boxShadow: 1,
                            borderRadius: 5,
                            padding: 1,
                        }} >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ThreeDText
                                    text="Customer Information"
                                    display=""
                                    className="three-d-text-container"
                                    justifyContent="left"
                                    alignItems="left" />
                            </Grid></Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8} sm={6} lg={8}>
                    <Paper elevation={0}
                        autoHeight
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#8899be",
                            boxShadow: 1,
                            borderRadius: 5,
                            padding: 1,
                        }} >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ThreeDText
                                    text="Bill Information"
                                    display=""
                                    className="three-d-text-container"
                                    justifyContent="left"
                                    alignItems="left" />
                            </Grid></Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    };

    return (
        <SnpComponent showDialog={snpComponent.showDialog}
            isLoader={snpLoader}
            title={snpComponent.title}
            subTitle={snpComponent.subTitle}
            negativeAction={snpComponent.negativeAction}
            positiveReq={snpComponent.positiveReq}
            positiveAction={snpComponent.positiveAction}
            negativeReq={snpComponent.negativeReq}
            handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
            iconReq={snpComponent.iconReq}
            iconContent={snpComponent.iconContent}
            content={renderSnpContent()}
            onSnackClose={() => setSnpComponent({
                ...snpComponent,
                snackBarOpen: false,
                snackBarMsg: "",
            })}
            cancelExtra={snpComponent.cancelExtra}
            snackBarOpen={snpComponent.snackBarOpen}
            snackBarMsg={snpComponent.snackBarMsg}
            outSideClickDismiss={snpComponent.outSideClickDismiss}
            gui={snpComponent.gui}
            snackHorizontal={snpComponent.snackHorizontal}
            snackVertical={snpComponent.snackVertical}
            snackColor={snpComponent.snackColor}
        />
    )
}