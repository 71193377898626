import React, { useLayoutEffect, useState } from "react";
import { Grid, TextField, Checkbox, Paper } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Loader from "../global/loader/Loader";
import {
    GET_CONVERSIONTYPE_DATA,
    SET_CONVERSIONTYPE_DATA,
    getPreUrlAccordingToCart,
    SUCCESS,
    CART_INITIAL_STATE,
    printDebugLog,
    STOCKPRICINGFEATURE,
    GETSINGLECONFIGS,
    GETVIDEOPATH,
} from "../../constants/constant";
import DialogBox from "../global/DialogBox";
import {
    Button, FormControlLabel
} from "@mui/material";
import CustomDataGrid from "../global/CustomDataGrid";
import { useSelector } from "react-redux";
import { snpAxios } from "../global/api";
import axios from "axios";
import { SCREEN_CUSTOM_UNIT } from "../../constants/VideoConstant";
import YouTubeVideo from "../global/YouTubeVideo";
import SingleFab from "../global/fab/SingleFab";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import EnterButton from "../global/EnterButton";
import ThreeDText from "../global/ThreeDText";

export default function Conversion() {
    const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [conversionTypeData, setConversionTypeData] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [stockPricingFeature, setStockPricingFeature] = useState(false);
    const [videoModel, setVideoModel] = useState({ show: false, path: "" });
    const [conversionTypeSingleData, setSingleConversionTypeData] = useState({
        cnvId: "",
        unitName: "",
        unitRestrictions: false,
        smallUnitName: "",
        smallUnitCount: 0,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //console.log("handleInputChange", name);
        if (name === 'unitRestrictions') {
            let previousCheckedValue = conversionTypeSingleData.unitRestrictions;

            setSingleConversionTypeData({
                ...conversionTypeSingleData,
                unitRestrictions: !previousCheckedValue,
            });
        } else {
            setSingleConversionTypeData({
                ...conversionTypeSingleData,
                [name]: value,
            });
        }
    };


    const columns = [
        { field: 'unitName', headerName: 'Unit Name', width: 170 },
        {
            field: 'unitRestrictions', headerName: 'Unit Restriction', width: 170,
            renderCell: (val) => {
                return (val.row.unitRestrictions) ? 'Yes' : 'No';
            }
        },
        { field: 'smallUnitCount', headerName: 'Count', width: 120 },
        { field: 'smallUnitName', headerName: 'SmallUnit Name', width: 150 },
        { field: 'cnvDetails', headerName: 'Display', width: 250 },
        {
            field: "View",
            headerName: "View",
            width: 120,
            renderCell: (cellValues) => {
                return (
                    <VisibilityIcon
                        onClick={(event) => {
                            handleCellClick(event, cellValues);
                        }}
                    >
                    </VisibilityIcon>
                );
            }
        }
    ];


    const handleCellClick = (param, event) => {
        setSingleConversionTypeData(event.row);
        setEdit(true);
        setShowDialog(true);
    };

    const handleSubmit = async () => {
        setFormSubmitted(true);
        if (!isValid()) {
            return;
        }
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + SET_CONVERSIONTYPE_DATA;
        //console.log("handleSubmit conversionTypeSingleData => ", conversionTypeSingleData);
        let filledData = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                cnvId: conversionTypeSingleData.id,
                unitName: conversionTypeSingleData.unitName,
                smallUnitName: conversionTypeSingleData.smallUnitName,
                unitCount: parseInt(conversionTypeSingleData.unitCount),
                smallUnitCount: parseInt(conversionTypeSingleData.smallUnitCount),
                unitRestrictions: conversionTypeSingleData.unitRestrictions,
            }
        };
        //console.log("handleSubmit => ", filledData);
        setShowDialog(false);
        snpAxios.post(catgUrl, filledData)
            .then(response => {
                console.log("responses => ", response.data);
                setDataLoaded(true);
                if (response.data.status === SUCCESS) {
                    getConversionData();
                }
            }).catch(error => {
                console.log("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getConversionData = async () => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_CONVERSIONTYPE_DATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                createNew: false,
            }
        };
        setDataLoaded(false);
        snpAxios.post(
            catgUrl,
            data
        )
            .then((response) => {
                setDataLoaded(true);
                printDebugLog("Conversion Data", response.data);
                if (response.data.status === SUCCESS) {
                    setConversionTypeData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                setDataLoaded(true);
                console.log("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getSingleConfigs = (mConfigName) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&configName=${mConfigName}` + `&shopHash=${storeActiveShopHash.id}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //console.log("getSingleConfigs => ", cUrl);
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //console.log("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    if (mConfigName === STOCKPRICINGFEATURE)
                        setStockPricingFeature(response.data === 'Yes');
                }
            }).catch((error) => {
                console.log("error : ", error);
                //window.location.href = "#/error";
            });
    };


    const getErrorMessage = (field) => {
        if (!formSubmitted) {
            return;
        } else if (conversionTypeSingleData[field] === 'number' && conversionTypeSingleData[field] === 0) {
            return 'Cannot be 0'
        } else if (!conversionTypeSingleData[field]) {
            return 'Field is required';
        }
    }

    const getYoutubeVideo = (videoName, lang) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETVIDEOPATH + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&name=${videoName}` + `&lang=${lang}`;
        let data = {};
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    setVideoModel({ ...videoModel, path: response.data });
                } else
                    setVideoModel({ ...videoModel, path: '' });
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
                setVideoModel({ ...videoModel, path: '' });
            });
    };

    useLayoutEffect(() => {
        //  console.log('Printing Login Details : ',CART_INITIAL_STATE);
        getYoutubeVideo(SCREEN_CUSTOM_UNIT, 'hi');
        getSingleConfigs(STOCKPRICINGFEATURE);
        getConversionData(0, 5);
    }, [storeCartType, storeApiUrl]);

    const renderDialogContent = () => {
        return (
            <>
                <Paper elevation={10}
                    autoHeight
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#eff2ff",
                        margin: "2px",
                        boxShadow: 1,
                        borderRadius: 5,
                        padding: 2,
                    }} >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ThreeDText
                                text="Unit details"
                                display=""
                                justifyContent="left"
                                alignItems="left" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="unitName"
                                label="Enter Unit Name"
                                name="unitName"
                                defaultValue={conversionTypeSingleData.unitName}
                                value={conversionTypeSingleData.unitName}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === "" || /^[a-zA-Z]*$/.test(value)) {
                                        setSingleConversionTypeData({
                                            ...conversionTypeSingleData,
                                            unitName: value,
                                        })
                                    }
                                }}
                                error={formSubmitted && conversionTypeSingleData.unitName === ""}
                                helperText={formSubmitted ? getErrorMessage('unitName') : "Enter Only Alphabets(A-Z a-z)"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel disabled={isEdit} control={<Checkbox checked={conversionTypeSingleData.unitRestrictions}
                                onChange={handleInputChange} id="unitRestrictions" />} name="unitRestrictions" label="Unit Restrictions" />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={10}
                    autoHeight
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#f9e7ff",
                        margin: "2px",
                        boxShadow: 1,
                        borderRadius: 5,
                        padding: 2,
                    }} >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ThreeDText
                                text="Small unit details"
                                display=""
                                className="three-d-text-container"
                                justifyContent="left"
                                alignItems="left" />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="smallUnitCount"
                                label="Enter SmallUnit Count"
                                name="smallUnitCount"
                                value={conversionTypeSingleData.smallUnitCount}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === "" || /^[0-9]*$/.test(value)) {
                                        setSingleConversionTypeData({
                                            ...conversionTypeSingleData,
                                            smallUnitCount: Number(value),
                                        });
                                    }
                                }}
                                disabled={isEdit}
                                error={formSubmitted && !conversionTypeSingleData.smallUnitCount}
                                helperText={formSubmitted ? getErrorMessage('smallUnitCount') : "Enter Only Digit(0-9)"}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="smallUnitName"
                                label="Enter SmallUnit Name"
                                name="smallUnitName"
                                defaultValue={conversionTypeSingleData.smallUnitName}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === "" || /^[a-zA-Z]*$/.test(value)) {
                                        setSingleConversionTypeData({
                                            ...conversionTypeSingleData,
                                            smallUnitName: value,
                                        })
                                    }
                                }}
                                error={formSubmitted && conversionTypeSingleData.smallUnitName === ""}
                                helperText={formSubmitted ? getErrorMessage('smallUnitName') : "Enter Only Alphabets(A-Z a-z)"}
                            />
                        </Grid></Grid>
                </Paper>
            </>
        );
    }

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                {
                    <SnpButton variant="contained" color="primary" onClick={handleSubmit}>
                        Add
                    </SnpButton>
                }
                <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
                    Dismiss
                </DismissButton>
            </React.Fragment>
        );
    }

    const isValid = () => {
        let isConversionDataValid = true;

        if (!conversionTypeSingleData.unitName || !conversionTypeSingleData.smallUnitName || !conversionTypeSingleData.smallUnitCount) {
            isConversionDataValid = false;
        }

        return isConversionDataValid;
    }

    const handleDialogClose = () => {
        //console.log("handleDialogClose 1");
        setSingleConversionTypeData({
            ...conversionTypeSingleData,
            unitName: "",
            unitCount: 1,
            unitRestrictions: false,
            smallUnitName: "",
            smallUnitCount: 1,
        });
        setShowDialog(false);
        setEdit(false);
        setFormSubmitted(false);
    };



    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                    {
                        stockPricingFeature && <div style={{ padding: 10 }}>
                            <EnterButton variant="contained" color="primary" sx={{ boxShadow: 12 }} style={{ float: 'right' }} className="float-right"
                                onClick={(event) => {
                                    setShowDialog(true);
                                }}>
                                Add New Conversion
                            </EnterButton>
                        </div>
                    }


                    <CustomDataGrid
                        getRowId={row => row.id}
                        loading={!isDataLoaded}
                        rows={conversionTypeData}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                    />
                </Grid>
            </Grid>
            < DialogBox
                showDialog={showDialog}
                title={!isEdit ? "Add New Conversion" : "View Conversion"}
                onClose={handleDialogClose}
                dialogContent={renderDialogContent()}
                dialogActions={renderDialogAction()}
            ></DialogBox>
            {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({ ...videoModel, show: false })} />}
            {videoModel.path && <SingleFab onClick={(e) => setVideoModel({ ...videoModel, show: true })} />}
        </div>
    );
}


