import React, { useLayoutEffect, useState } from "react";
import CustomDataGrid from "../global/CustomDataGrid";
import { Grid, Paper, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
//import MenuItem from "@material-ui/core/MenuItem";
//import Input from '@material-ui/core/Input';
import Loader from "../global/loader/Loader";
import {
    GET_SUPPLIER_DATA,
    SET_SUPPLIER_DATA,
    getPreUrlAccordingToCart,
    SUCCESS,
    CART_INITIAL_STATE,
    isRoleAllowed,
    SUPPLIERUPDATE,
    printDebugLog
} from "../../constants/constant";
import DialogBox from "../global/DialogBox";
import {
    RadioGroup, FormControlLabel,
    FormLabel, Radio
} from "@mui/material";
import { useSelector } from "react-redux";
import { snpAxios } from '../global/api';
import SnpComponent from "../global/SnpComponent";
import ThreeDText from "../global/ThreeDText";
import EnterButton from "../global/EnterButton";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";


export default function SupplierInfo() {
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
    const storeActiveShopHash = useSelector(
        (state) => state.setActiveShopHashReducer.activeShopHash
    );
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: false,
        iconContent: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
    };
    const INITIAL_SUPPLIER_DETAILS = {
        id: "",
        supplierId: "",
        openingBal: 0.0,
        drCr: 1,
        dob: "",
        supplierName: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        anniversary: "",
        emailId: "",
        Phone: "",
        gstnumber: "",
        bankaccnumber: "",
        bankname: "",
        ifsccode: "",
        panNo: "",
        aadharNo: "",
        drivingLicense_1: "",
        drivingLicense_2: "",
        chequePrintName: "",
        contactPersonName: "",
        contactPersonMobNo: "",
        creditLimit: 0.0,
        billLimit: 0.0,
        creditDaysForSale: 0,
        creditDaysForPurhase: 0,
        noteText: "",
        remarks: "",
        activeStatus: 1,
        createdDate: "",
    };

    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [supplierData, setSupplierData] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [supplierSingleData, setSinglesupplierData] = useState(INITIAL_SUPPLIER_DETAILS);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //console.log("handleInputChange",name , value);
        if (name === 'Phone') {
            if (value.length <= 10) {
                setSinglesupplierData({
                    ...supplierSingleData,
                    [name]: value,
                });
            } else {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Mobile Number should be 10 digit",
                });
            }
        } else {
            setSinglesupplierData({
                ...supplierSingleData,
                [name]: value,
            });
        }

        //console.log("setSinglesupplierData-> ",supplierSingleData);
    };



    const columns = [
        { field: 'supplierId', headerName: 'Id', width: 140 },
        { field: 'supplierName', headerName: 'Supplier Name', width: 180 },
        { field: 'emailId', headerName: 'Email', width: 200 },
        { field: 'Phone', headerName: 'Phone', width: 150 },
        {
            field: 'activeStatus', headerName: 'Status', width: 150,
            renderCell: (val) => {
                return (val.row.activeStatus === 1) ? 'Active' : 'InActive';
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            width: 150,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            if (isRoleAllowed(SUPPLIERUPDATE)) {
                                handleCellClick(event, cellValues);
                            } else {
                                setSnpComponent({
                                    ...snpComponent,
                                    isLoader: false,
                                    negativeReq: false,
                                    positiveReq: true,
                                    positiveAction: "Ok Got it",
                                    showDialog: true,
                                    outSideClickDismiss: true,
                                    cancelExtra: true,
                                    requestType: 99,
                                    subTitle: "You Are not allowed to Add/Update Supplier Details.Contact the Owner",
                                    handleSnpSubmit: () => handleDialogClose(),
                                });
                            }

                        }}
                    >
                    </EditIcon>
                );
            }
        }
    ];


    const handleCellClick = (param, event) => {
        setSinglesupplierData({
            ...supplierSingleData,
            id: event.row.id,
            supplierId: event.row.supplierId,
            openingBal: event.row.openingBal,
            drCr: event.row.drCr,
            dob: event.row.dob,
            supplierName: event.row.supplierName,
            address_1: event.row.address_1,
            address_2: event.row.address_2,
            city: event.row.city,
            state: event.row.state,
            anniversary: event.row.anniversary,
            emailId: event.row.emailId,
            Phone: event.row.Phone,
            gstnumber: event.row.gstnumber,
            bankaccnumber: event.row.bankaccnumber,
            bankname: event.row.bankname,
            ifsccode: event.row.ifsccode,
            panNo: event.row.panNo,
            aadharNo: event.row.aadharNo,
            drivingLicense_1: event.row.drivingLicense_1,
            drivingLicense_2: event.row.drivingLicense_2,
            chequePrintName: event.row.chequePrintName,
            contactPersonName: event.row.contactPersonName,
            contactPersonMobNo: event.row.contactPersonMobNo,
            creditLimit: event.row.creditLimit,
            billLimit: event.row.billLimit,
            creditDaysForSale: event.row.creditDaysForSale,
            creditDaysForPurhase: event.row.creditDaysForPurhase,
            noteText: event.row.noteText,
            remarks: event.row.remarks,
            activeStatus: event.row.activeStatus,
            createdDate: event.row.createdDate,
        });
        setShowDialog(true);
        setEdit(true);
    };

    const getErrorMessage = (field) => {
        if (!formSubmitted) {
            return;
        } else if (supplierSingleData[field] === 'number' && supplierSingleData[field] === 0) {
            return 'Cannot be 0'
        } else if (!supplierSingleData[field]) {
            return 'Field is required';
        }
    }

    const handleUpdateSupplierData = async () => {
        setFormSubmitted(true);
        if (!isValid()) {
            return;
        }

        let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + SET_SUPPLIER_DATA;
        let filledData = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                id: supplierSingleData.id,
                supplierId: supplierSingleData.supplierId,
                openingBal: parseFloat(supplierSingleData.openingBal),
                drCr: parseInt(supplierSingleData.drCr),
                dob: supplierSingleData.dob,
                supplierName: supplierSingleData.supplierName,
                address_1: supplierSingleData.address_1,
                address_2: supplierSingleData.address_2,
                city: supplierSingleData.city,
                state: supplierSingleData.state,
                anniversary: supplierSingleData.anniversary,
                emailId: supplierSingleData.emailId,
                Phone: supplierSingleData.Phone,
                gstnumber: supplierSingleData.gstnumber,
                bankaccnumber: supplierSingleData.bankaccnumber,
                bankname: supplierSingleData.bankname,
                ifsccode: supplierSingleData.ifsccode,
                panNo: supplierSingleData.panNo,
                aadharNo: supplierSingleData.aadharNo,
                drivingLicense_1: supplierSingleData.drivingLicense_1,
                drivingLicense_2: supplierSingleData.drivingLicense_2,
                chequePrintName: supplierSingleData.chequePrintName,
                contactPersonName: supplierSingleData.contactPersonName,
                contactPersonMobNo: supplierSingleData.contactPersonMobNo,
                creditLimit: parseFloat(supplierSingleData.creditLimit),
                billLimit: parseFloat(supplierSingleData.billLimit),
                creditDaysForSale: parseInt(supplierSingleData.creditDaysForSale),
                creditDaysForPurhase: parseInt(supplierSingleData.creditDaysForPurhase),
                noteText: supplierSingleData.noteText,
                remarks: supplierSingleData.remarks,
                activeStatus: parseInt(supplierSingleData.activeStatus),
            }
        };
        // console.log("handleUpdateSupplierData => ", filledData);
        snpAxios.post(catgUrl, filledData)
            .then(response => {
                printDebugLog("responses => ", response.data);
                setShowDialog(false);
                setSinglesupplierData(INITIAL_SUPPLIER_DETAILS);
                if (response.data.status === SUCCESS) {
                    getSupplierDetails();
                }
            }).catch(error => {
                setShowDialog(false);
                setSinglesupplierData(INITIAL_SUPPLIER_DETAILS);
                setFormSubmitted(false);
                console.log("error : ", error);
                // window.location.href = "#/error";
            });
    };

    const getSupplierDetails = async () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_SUPPLIER_DATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        //  console.log("getSupplierDetails  => ",data);
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                printDebugLog("response   => ", response.data);
                setDataLoaded(true);
                if (response.data.status === SUCCESS) {
                    setSupplierData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                setDataLoaded(true);
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };


    useLayoutEffect(() => {
        //   console.log('Printing Login Details : ', CART_INITIAL_STATE);
        getSupplierDetails();
    }, [storeCartType, storeApiUrl, storeActiveShopHash]);

    const handleDialogClose = () => {
        // console.log("handleDialogClose");
        setSinglesupplierData(INITIAL_SUPPLIER_DETAILS);
        setShowDialog(false);
        setEdit(false);
        setFormSubmitted(false);
        setSnpComponent(INITIAL_SNP_COMPONENT);
    };

    const isValid = () => {
        let isSupplierDataValid = true;

        if (!supplierSingleData.supplierId || !supplierSingleData.supplierName ||
            !supplierSingleData.Phone ||
            !supplierSingleData.address_1 ||
            !supplierSingleData.aadharNo) {
            isSupplierDataValid = false;
        }

        return isSupplierDataValid;
    }

    const renderDialogContent = () => {
        return (
            <div>
                <Paper elevation={10}
                    autoHeight
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#f7cac9",
                        margin: "2px",
                        boxShadow: 1,
                        borderRadius: 5,
                        padding: 2,
                    }} >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ThreeDText
                                text="Supplier Information"
                                display=""
                                className="three-d-text-container"
                                justifyContent="left"
                                alignItems="left" />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                required
                                id="supplierId"
                                name="supplierId"
                                variant="filled"
                                size="small"
                                label="Supplier Id"
                                fullWidth
                                value={supplierSingleData.supplierId}
                                onChange={handleInputChange}
                                error={formSubmitted && supplierSingleData.supplierId === ""}
                                helperText={getErrorMessage('supplierId')}
                            />
                        </Grid>

                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                required
                                id="supplierName"
                                name="supplierName"
                                variant="filled"
                                size="small"
                                label="Supplier Name"
                                fullWidth
                                value={supplierSingleData.supplierName}
                                onChange={handleInputChange}
                                error={formSubmitted && supplierSingleData.supplierName === ""}
                                helperText={getErrorMessage('supplierName')}
                            />
                        </Grid>


                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="dob"
                                name="dob"
                                variant="filled"
                                size="small"
                                label="Date of Birth"
                                fullWidth
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={supplierSingleData.dob}
                                onChange={handleInputChange}
                            />
                        </Grid>

                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="emailId"
                                name="emailId"
                                variant="filled"
                                size="small"
                                label="Email Id "
                                value={supplierSingleData.emailId}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>

                            <TextField
                                required
                                id="Phone"
                                name="Phone"
                                variant="filled"
                                size="small"
                                label="Phone"
                                value={supplierSingleData.Phone}
                                onChange={handleInputChange}
                                fullWidth
                                error={formSubmitted && !supplierSingleData.Phone}
                                helperText={getErrorMessage('Phone')}
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                required
                                id="address_1"
                                name="address_1"
                                variant="filled"
                                size="small"
                                label="Address "
                                fullWidth
                                value={supplierSingleData.address_1}
                                onChange={handleInputChange}
                                error={formSubmitted && !supplierSingleData.address_1}
                                helperText={getErrorMessage('address_1')}
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="address_2"
                                name="address_2"
                                variant="filled"
                                size="small"
                                label="Address 2"
                                fullWidth
                                value={supplierSingleData.address_2}
                                onChange={handleInputChange}
                            />
                        </Grid>

                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="city"
                                name="city"
                                label="City"
                                variant="filled"
                                size="small"
                                value={supplierSingleData.city}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                error={formSubmitted && !supplierSingleData.city}
                                helperText={getErrorMessage('city')}
                            />

                        </Grid>

                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="state"
                                name="state"
                                label="State"
                                variant="filled"
                                size="small"
                                value={supplierSingleData.state}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                error={formSubmitted && !supplierSingleData.state}
                                helperText={getErrorMessage('state')}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={10}
                    autoHeight
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#dec2cb",
                        margin: "2px",
                        boxShadow: 1,
                        borderRadius: 5,
                        padding: 2,
                    }} >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ThreeDText
                                text="Document Information"
                                display=""
                                className="three-d-text-container"
                                justifyContent="left"
                                alignItems="left" />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="panNo"
                                name="panNo"
                                variant="filled"
                                size="small"
                                label="Enter Pan No"
                                value={supplierSingleData.panNo}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                required
                                id="aadharNo"
                                name="aadharNo"
                                variant="filled"
                                size="small"
                                label="Enter Aadhar No"
                                value={supplierSingleData.aadharNo}
                                onChange={handleInputChange}
                                fullWidth
                                error={formSubmitted && !supplierSingleData.aadharNo}
                                helperText={getErrorMessage('aadharNo')}
                            />
                        </Grid>


                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="drivingLicense_1"
                                name="drivingLicense_1"
                                variant="filled"
                                size="small"
                                label="Select Driving Licence 1"
                                value={supplierSingleData.drivingLicense_1}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="drivingLicense_2"
                                name="drivingLicense_2"
                                variant="filled"
                                size="small"
                                label="Select Driving Licence 2(If Any)"
                                value={supplierSingleData.drivingLicense_2}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={10}
                    autoHeight
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#c5b9cd",
                        margin: "2px",
                        boxShadow: 1,
                        borderRadius: 5,
                        padding: 2,
                    }} >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ThreeDText
                                text="Account Information"
                                display=""
                                justifyContent="left"
                                className="three-d-text-container"
                                alignItems="left" />
                        </Grid>
                        <Grid item xs={12}>

                            <FormLabel component="legend">Current Opening Balance</FormLabel>
                            <RadioGroup row aria-label="drCr"
                                size="small"
                                name="drCr" value={supplierSingleData.drCr.toString()} onChange={handleInputChange} >
                                <FormControlLabel disabled value="1" control={<Radio />} variant="filled"
                                    size="small"
                                    label="Debit" />
                                <FormControlLabel disabled value="2" control={<Radio />} variant="filled"
                                    size="small"
                                    label="Credit" />
                            </RadioGroup>
                        </Grid>


                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                disabled
                                id="openingBal"
                                name="openingBal"
                                variant="filled"
                                size="small"
                                label="Opening Balance"
                                value={supplierSingleData.openingBal.toString()}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="creditLimit"
                                name="creditLimit"
                                variant="filled"
                                size="small"
                                label="Enter Credit limit"
                                value={supplierSingleData.creditLimit.toString()}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="creditDaysForSale"
                                name="creditDaysForSale"
                                variant="filled"
                                size="small"
                                label="Enter Credit Days for Sale"
                                value={supplierSingleData.creditDaysForSale.toString()}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="bankname"
                                name="bankname"
                                variant="filled"
                                size="small"
                                label="Enter Bank Name"
                                value={supplierSingleData.bankname.toString()}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="ifsccode"
                                name="ifsccode"
                                variant="filled"
                                size="small"
                                label="Enter IFSC Code"
                                value={supplierSingleData.ifsccode}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="contactPersonName"
                                name="contactPersonName"
                                variant="filled"
                                size="small"
                                label="Enter Contact Person Name"
                                value={supplierSingleData.contactPersonName}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="billLimit"
                                name="billLimit"
                                variant="filled"
                                size="small"
                                label="Enter Bill limit"
                                value={supplierSingleData.billLimit.toString()}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="creditDaysForPurhase"
                                name="creditDaysForPurhase"
                                variant="filled"
                                size="small"
                                label="Enter Credit Days for Purchase"
                                value={supplierSingleData.creditDaysForPurhase.toString()}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="bankaccnumber"
                                name="bankaccnumber"
                                variant="filled"
                                size="small"
                                label="Enter Account Number"
                                value={supplierSingleData.bankaccnumber.toString()}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="chequePrintName"
                                name="chequePrintName"
                                variant="filled"
                                size="small"
                                label="Enter Cheque Print name"
                                value={supplierSingleData.chequePrintName}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="gstnumber"
                                name="gstnumber"
                                variant="filled"
                                size="small"
                                label="Enter GST Number"
                                value={supplierSingleData.gstnumber}
                                onChange={handleInputChange}
                                fullWidth

                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="contactPersonMobNo"
                                name="contactPersonMobNo"
                                variant="filled"
                                size="small"
                                label="Enter Contact Person Mob No"
                                value={supplierSingleData.contactPersonMobNo}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={10}
                    autoHeight
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#abb1cf",
                        margin: "2px",
                        boxShadow: 1,
                        borderRadius: 5,
                        padding: 2,
                    }} >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ThreeDText
                                text="Other Information"
                                display=""
                                className="three-d-text-container"
                                justifyContent="left"
                                alignItems="left" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">Enter Supplier Status</FormLabel>
                            <RadioGroup row aria-label="activeStatus"
                                size="small"
                                name="activeStatus" value={supplierSingleData.activeStatus.toString()} onChange={handleInputChange} >
                                <FormControlLabel value="1" control={<Radio />} variant="filled"
                                    size="small"
                                    label="Active" />
                                <FormControlLabel value="2" control={<Radio />} variant="filled"
                                    size="small"
                                    label="InActive" />
                            </RadioGroup>
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="noteText"
                                name="noteText"
                                variant="filled"
                                size="small"
                                rows={3}
                                multiline
                                label="Enter Note Text"
                                value={supplierSingleData.noteText}
                                onChange={handleInputChange}
                                fullWidth

                            />
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <TextField
                                id="remarks"
                                name="remarks"
                                multiline
                                rows={3}
                                variant="filled"
                                size="small"
                                label="Enter Remarks"
                                value={supplierSingleData.remarks}
                                onChange={handleInputChange}
                                fullWidth

                            />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }



    const renderDialogAction = () => {
        return (
            <React.Fragment>
                <SnpButton variant="contained" onClick={handleUpdateSupplierData}>
                    {isEdit ? "Update" : "Submit"}
                </SnpButton>
                <DismissButton variant="contained" onClick={handleDialogClose}>
                    Dismiss
                </DismissButton>
            </React.Fragment>
        );
    }


    const renderSnpComponent = () => {
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ padding: 10 }}>
                            <EnterButton
                                variant="contained"
                                style={{ float: "right" }}
                                onClick={(event) => {
                                    setShowDialog(true);
                                }}>
                                Add New Supplier Information
                            </EnterButton>
                        </div>

                        {isDataLoaded ? (
                            <CustomDataGrid
                                getRowId={row => row.id}
                                rows={supplierData}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                autoHeight
                            />
                        ) : (
                            <Loader />
                        )}
                    </Grid>
                </Grid>
                < DialogBox
                    showDialog={showDialog}
                    title={isEdit ? "Update Supplier Information" : "Add New Supplier Information"}
                    onClose={handleDialogClose}
                    dialogContent={renderDialogContent()}
                    dialogActions={renderDialogAction()}
                ></DialogBox>
            </>
        );
    };

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    return (
        <>
            <SnpComponent showDialog={snpComponent.showDialog}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
                iconReq={snpComponent.iconReq}
                iconContent={snpComponent.iconContent}
                content={renderSnpComponent()}
                onSnackClose={handleSnackClose}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={snpComponent.handleSnpCancel}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                handleSubmit={snpComponent.handleSnpSubmit}
                gui={snpComponent.gui}
            />
        </>
    );

}


