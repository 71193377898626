import { Autocomplete, Avatar, Box, Container, CssBaseline, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import BillConnector from "../billing/BillConnector";
import OwnerDashboard from './OwnerDashboard';
import Configuration from '../configuration/Configuration';
import Category from '../Category';
import ProductEntry from '../product/ProductEntry';
import SupplierInfo from '../supplier/SupplierInfo';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Employee from '../employee/Employee';
import StockEntry from '../stockentry/StockEntry';
import Conversion from '../customunit/Conversion';
import RestoProductStock from '../resturant/RestoProductStock';
import BillingModule from '../billing/BillingModule';
import BillingTable from '../billing/BillingTable';
import BookTable from '../booktable/BookTable';
import ComboOffer from '../combooffer/ComboOffer';
import RestoBillingModule from '../billing/RestoBillingModule';
import ShowBills from '../billing/ShowBills';
import HelpModule from '../help/HelpModule';
import ThemeManager from '../theme/ThemeManager';
import Profile from '../profile/Profile';
import Customer from '../customer/Customer';
import Accounting from '../accounting/Accounting';
import ItemSaleDashboard from './ItemSaleDashboard';
import RestoStockInformation from '../resturant/RestoStockInformation'
import ReturnWasteBill from '../billing/ReturnWasteBill';
import RiderDashBoard from '../rider/RiderDashboard';
import Receipe from '../receipe/Receipe';
import NotificationSound from "./NotificationSound";
import Loader from "../global/loader/Loader";
import {
  ACTMGMT,
  BILLMGMT, CART_INITIAL_STATE,
  CartDets,
  CHANGEPASSWORD,
  COMBOFOODITEMGMT,
  COMBOMGMT,
  CUSTOMERAPP,
  CUSTOMERMGMT,
  CUSTOMIZEDCONFIG,
  CUSTOMUNITMGMT,
  DashboardComponent,
  EMPMGMT,
  FOODITEMPRODUCTMGMT,
  getPreUrlAccordingToCart,
  GETPRODDETAILS,
  InternalBillType,
  INVSTOCKMGMT,
  isFeatureAllowed,
  isNumberEmpty,
  LOGOUT,
  offline_enable,
  ORDERRECEIVED,
  OWNERDASHBOARD,
  Print_Log,
  printDebugLog,
  printInfoLog,
  RECPMGMT,
  REPGEN,
  RETURNWASTEMGMT,
  ROLEUPDATE,
  setLogout,
  SHOWITEMWISESALES,
  STOCKMGMT,
  SUCCESS,
  SUPMGMT,
  test_environment_mode,
  THEMEMGMT,
  UPDATEACTIVESHOPSESSION,
  UPDATEHOMEDELIVERY,
  VALIDITYEXPIREDERR
} from "../../constants/constant";
import { setActiveShopHash, setBaseCateogoryData, setCartType } from "../../actions";
import { snpAxios } from "../global/api";
import "./Dashboard.css";
import 'react-notifications/lib/notifications.css';
import ConfirmDialog from "../global/ConfirmDialog";
import FeatureGuard from "../global/FeatureGuard";
import { isEmpty } from "lodash";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import axios from "axios";
import utilities from "../../utilities/utilities";
import * as websocket from 'websocket';
import { getQueryParam } from '../../utilities/queryParamUtils';
import DialogBox from "../global/DialogBox";
import MartBilling from "../billing/MartBilling";

export default function Dashboard() {
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const KEEP_ALIVE_INTERVAL = test_environment_mode ? 60 * 60 * 1000 : 5 * 60 * 1000; // 1 minutes to 45 sec to 5 min
  const INITIAL_MESSAGE = {
    message: '',
    show: false,
    oldPassWord: "",
    newPassWord: ""
  };

  const INITIAL_DIALOG_CONTENT = {
    showDialog: false,
    title: "",
    subTitle: "",
    negativeReq: true,
    negativeAction: "",
    positiveReq: false,
    positiveAction: "",
    iconReq: false,
    cancelConfirmExtra: false,
    outsideClickedConfirmDismiss: false,
    onHandleCancel: () => { },
  };

  const INITIAL_STOCK_CONTENT = {
    id: "",
    details: "",
    type:"",
    variant:"",
    size:"",
    liveStockData:"",
    liveStockOnlineData:"",
    expiryLiveStockData:"",
    cnvDetails: "",
    rack_no:"",
  };


  const [open, setOpen] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loggedOut, setLogoutUser] = useState(false);
  const [notificationSound, setNotificationSound] = useState(INITIAL_MESSAGE);
  const [expiryDialog, setExpiryDialog] = useState(INITIAL_MESSAGE);
  const [passwordDialog, setPasswordDialog] = useState(INITIAL_MESSAGE);
  const [lockDialog, setLockDialog] = useState(INITIAL_MESSAGE);
  const [searchDialog, setSearchDialog] = useState(INITIAL_MESSAGE);
  const [confirmDialog, setConfirmDialog] = useState(INITIAL_DIALOG_CONTENT);

  const [isGetStockDetails, setIsGetStockDetails] = useState(false);
  const [listDataAvail, setListDataAvail] = useState(false);
  const [listStock, setListStock] = useState([]);
  const [stockDetails, setStockDetails] = useState(INITIAL_STOCK_CONTENT);
  const [inactive, setInactive] = useState(false);
  let inactivityTimer;

  const logoutUser = async () => {
    try {
      setLoader(true);
      setConfirmDialog(INITIAL_DIALOG_CONTENT);
      const logouturl = `${storeApiUrl.BACKEND_BASE_URL}${LOGOUT}`;
      const data = { jcJson: { value_1: CART_INITIAL_STATE.apiToken, } };
      const response = snpAxios.post(logouturl, data);
      printDebugLog("logoutUser", response);
      sessionStorage.removeItem("carInitialState");
      setLogout();
      dispatch(setCartType(CartDets.All));
      setLoader(false);
      setLogoutUser(true);
    } catch (error) {
      console.error("logoutUser errr ", error);
      sessionStorage.removeItem("carInitialState");
      dispatch(setCartType(CartDets.All));
      setLogout();
      setLogoutUser(true);
    }
  };

  const handleChangePwd = async () => {
    if (isEmpty(passwordDialog.oldPassWord)) {
      setPasswordDialog(prvs => ({
        ...prvs, message: "Old Password cannot be empty"
      }));
      return;
    }
    if (isEmpty(passwordDialog.newPassWord)) {
      setPasswordDialog(prvs => ({
        ...prvs, message: "New Password cannot be empty"
      }));
      return;
    }
    if (CART_INITIAL_STATE.userPassWord !== passwordDialog.oldPassWord) {
      setPasswordDialog(prvs => ({
        ...prvs, message: "Please enter currect old password"
      }));
      return;
    }
    if (CART_INITIAL_STATE.userPassWord === passwordDialog.newPassWord) {
      setPasswordDialog(prvs => ({
        ...prvs, message: "Old Password and new Password can't be same"
      }));
      return;
    }
    try {
      setLoader(true);
      const logouturl = `${storeApiUrl.BACKEND_BASE_URL}${CHANGEPASSWORD}`;
      const data = {
        jcJson: {
          apiToken: CART_INITIAL_STATE.apiToken,
          value_1: passwordDialog.newPassWord,
        }
      };
      const response = await snpAxios.post(logouturl, data);
      if (response.data.status === SUCCESS) {
        CART_INITIAL_STATE.userPassWord = passwordDialog.newPassWord;
        CART_INITIAL_STATE.firstTimeLogin = false;
        setPasswordDialog(INITIAL_MESSAGE);
        setConfirmDialog({
          ...confirmDialog,
          showDialog: true,
          subTitle: "SuccessFully Changed the Password! Please Login Again.",
          positiveAction: "Login!",
          positiveReq: true,
          negativeReq: false,
        })
      }
      setLoader(false);
    } catch (error) {
      console.error("handleChangePwd errr ", error);
      setLoader(false);
    }
  };

  const getProductDetails = async (stock) => {
    try {
      setLoader(true);
      const productDetails = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GETPRODDETAILS}?adminId=${CART_INITIAL_STATE.apiToken}&stock=${stock}&shopHash=${storeActiveShopHash.id}`;
      const response = await axios.get(productDetails, {});
      if (response.data.status === SUCCESS) {
        setListDataAvail(true);
        setListStock(response.data.jcJson.value_2);
      }
      setLoader(false);
    } catch (error) {
      console.error("getProductDetails error : ", error);
      setLoader(false);
    }
  };

  const signIn = async () => {
    try {
      if (isEmpty(lockDialog.oldPassWord)) {
        setLockDialog(prvs => ({
          ...prvs, message: "Please enter password & sign in!"
        }));
        return;
      }
      setLoader(true);
      const mPwd = utilities.encode(lockDialog.oldPassWord);
      const mUrl = `${storeApiUrl.BACKEND_BASE_URL}a2z-token-refresh?token=${CART_INITIAL_STATE.apiToken}&val=${mPwd}`;
      const response = await axios.get(mUrl, {});
      printDebugLog("signIn response => ", response.data);
      setLoader(false);
      if (response.data.status === SUCCESS) {
        CART_INITIAL_STATE.apiToken = response.data.jcJson.token;
        setLockDialog(INITIAL_MESSAGE);
      } else {
        setLockDialog(prvs => ({
          ...prvs, message: "Entered password is wrong. Try again",
          oldPassWord: "",
        }));
      }
    } catch (error) {
      setLoader(false);
      console.error("signIn error : ", error);
      setLockDialog(prvs => ({
        ...prvs, message: "Something wrong. Try again",
        oldPassWord: "",
      }));
    }
  };

  const updateCartValue = (event, selectedHash) => {
    setExpiryDialog(INITIAL_MESSAGE);
    setLoader(true);
    if (selectedHash != null) {
      const cartUrl = `${storeApiUrl.BACKEND_BASE_URL}${UPDATEACTIVESHOPSESSION}`;
      const data = { jcJson: { apiToken: CART_INITIAL_STATE.apiToken, shopHash: selectedHash.id }, };
      snpAxios
        .post(cartUrl, data)
        .then((response) => {
          // console.log("response  => ", response.data);
          printDebugLog("updateCartValue", response.data);
          if (response.data.status === SUCCESS) {
            let responseShopHash = selectedHash;
            responseShopHash.defScreen = response.data.jcJson.defScreen;
            responseShopHash.companyName = response.data.jcJson.companyName;
            responseShopHash.companyWebAddress = response.data.jcJson.companyWebAddress;
            responseShopHash.companyMail = response.data.jcJson.companyMail;
            responseShopHash.packages = response.data.jcJson.packages;
            responseShopHash.onlineDelvMethod = response.data.jcJson.onlineDelvMethod;
            responseShopHash.validity = response.data.jcJson.validity;
            responseShopHash.totalValidity = response.data.jcJson.totalValidity;
            responseShopHash.startDate = response.data.jcJson.startDate;
            responseShopHash.originalStartDate = response.data.jcJson.originalStartDate;
            responseShopHash.defBalanceType = response.data.jcJson.defBalanceType;
            responseShopHash.kotCopiesAllowed = response.data.jcJson.kotCopiesAllowed;
            dispatch(setActiveShopHash(responseShopHash));
            dispatch(setCartType(responseShopHash.cartType));
            const worker = new Worker(new URL('../billing/GetDataFromDbWorker.js', import.meta.url));
            worker.postMessage(responseShopHash.id);
            worker.onmessage = (e) => {
              let savedData = e.data;
              printDebugLog("GetDataFromDbWorker Profile", savedData);
              if (savedData !== undefined && savedData.data !== undefined) {
                if (!"fullData" === savedData.type) {
                  dispatch(setBaseCateogoryData(savedData.data.value));
                }
              }
            };

            worker.onerror = (error) => {
              console.log("Worker error:", error);
            };

            NotificationManager.success("", "Changed to " + selectedHash.shop + "\nsuccessfully done.");
          } else {
            NotificationManager.error("Changed to " + selectedHash.shop + "\not happen.Try again!");
          }
        })
        .catch((error) => {
          console.error("updateCartValue errr ", error);
          if (VALIDITYEXPIREDERR === error.code) {
            handleThrowValidityErrorByShop(error.message);
          } else {
            NotificationManager.error("Changed to " + selectedHash.shop + "\nnot happen.Try again!");
          }
        });
    }
  };


  /* Only Function Call */

  const getKeyByValue = (CartDets, value) => {
    //console.log("getKeyByValue",CartDets,value);
    return value.location + "(" + Object.keys(CartDets).find(key => CartDets[key] === value.cartType) + ")";
  }
  const handleUpdateStockDetails = (event, option) => {
    if (option != null) {
      //console.log("handleUpdateStockDetails", event, option);
      setStockDetails({
        ...stockDetails, ...option,
      });
    }
  };
  const clearStockDetails = () => {
    setIsGetStockDetails(false);
    setStockDetails(INITIAL_STOCK_CONTENT);
    setListStock([]);
  };
  const handleSearch = (event) => {
    const { name, value } = event.target;
    //console.log(name,value)
    if (name === 'gsd') {
      setIsGetStockDetails(true);
    } else if (name === 'lse') {
      const mPrdId = stockDetails.id;
      setSearchDialog(INITIAL_MESSAGE);
      clearStockDetails();
      navigate("/dashboard/stockentry", { state: { prdId: mPrdId, isEditStock: 1 } });
    } else if (name === 'sb') {
      const mPrdId = stockDetails.id;
      setSearchDialog(INITIAL_MESSAGE);
      clearStockDetails();
      navigate("/dashboard/billentry", { state: { prdId: mPrdId } });
    } else {
      if (value.length >= 3) {
        clearStockDetails();
        getProductDetails(value);
      }
    }
  };

  const handleSignInKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      signIn();
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLoader = (loaded) => {
    setLoader(loaded);
  };

  const offlineLogout = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  const handleThrowValidityErrorByShop = (msg) => {
    setExpiryDialog({
      ...expiryDialog, message: msg, show: true
    })
  };

  const setComponentIndex = (index) => {
    printDebugLog("setComponentIndex", index);
    switch (index) {
      case DashboardComponent.LOGOUTMESSAGE:
        setConfirmDialog({
          ...confirmDialog,
          showDialog: true, title: "Logout!",
          subTitle: "Do You Want to Logout", negativeAction: "No",
          positiveReq: true
        });
        break;
      case DashboardComponent.LOGOUT:
        logoutUser();
        break;
      case DashboardComponent.PASSWORDDIALOG:
        setPasswordDialog({
          ...passwordDialog, show: true,
        });
        break;
      case DashboardComponent.SEARCHDIALOG:
        setSearchDialog({
          ...searchDialog, show: true, oldPassWord: "",
        });
        break;
      case DashboardComponent.LOCKDIALOG:
        setLockDialog({
          ...lockDialog, show: true, oldPassWord: "",
        });
        break;
      default:
        console.warn("setComponentIndex default ", index);
    }
    setOpen(false);
  };

  const showDialogBox = (msg) => {
    setConfirmDialog({
      ...confirmDialog,
      showDialog: true,
      subTitle: msg,
      negativeAction: "Got It!",
    });
  };

  const checkPersistence = async () => {
    if ('storage' in navigator && 'persist' in navigator.storage) {
      try {
        const persistent = await navigator.storage.persist();
        if (persistent) {
          console.info("Storage will not be cleared except by explicit user action");
        } else {
          console.warn("Storage may be cleared by the UA under storage pressure.");
        }
      } catch (err) {
        console.error('Error requesting persistent storage:', err);
      }
    } else {
      console.warn("Storage is not available");
    }
  };

  const inititateNotification = () => {
    if (!("Notification" in window)) {
      console.info("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
      printDebugLog("Browser support notification");
    }
  };

  const getDefaultScreen = () => {
    return CART_INITIAL_STATE.defScreen === DashboardComponent.REPORTMANAGEMENT
      ? isFeatureAllowed(OWNERDASHBOARD) ? <OwnerDashboard /> : null
      : CART_INITIAL_STATE.defScreen === DashboardComponent.BILLDETAILS
        ? isFeatureAllowed(BILLMGMT) ? <BillingTable showDialogBoxWithPaper={showDialogBox} /> : null
        : CART_INITIAL_STATE.defScreen === DashboardComponent.BILLCONNECTOR
          ? isFeatureAllowed(BILLMGMT) ? <BillConnector /> : null : null
  };

  const handleRoleUpdate = () => {
    setConfirmDialog({
      ...confirmDialog,
      showDialog: true, title: "Role Updated!",
      subTitle: "Your Role is Updated! Please SignIn Again", positiveAction: "Sign Out",
      positiveReq: true,
      outsideClickedConfirmDismiss: false,
      cancelConfirmExtra: true,
    });
  }

  const EnableSound = () => {
    setNotificationSound({
      message: '',
      show: true,
    });
    // Hide the notification after 5 seconds
    setTimeout(() => {
      setNotificationSound({
        message: '',
        show: false,
      });
    }, 1000);
  };

  const handleSocketMessage = (obj) => {
    if (!isNumberEmpty(obj.status)) {
      if (parseInt(obj.status) === UPDATEHOMEDELIVERY) {
        CART_INITIAL_STATE.homeDelvMethod = [{}];
        CART_INITIAL_STATE.homeDelvMethod = obj.jcJson.homeDelvMethod;
        //console.log('Received message2:', CART_INITIAL_STATE);
      } else if (parseInt(obj.status) === ROLEUPDATE) {
        CART_INITIAL_STATE.roles = [{}];
        CART_INITIAL_STATE.roles = obj.jcJson.roles;
        handleRoleUpdate();
        //console.log('Received message3:', CART_INITIAL_STATE);
      } else if (parseInt(obj.status) === ORDERRECEIVED) {
        const payLoad = obj.jcJson;
        //playAudio();
        const mTitle = isEmpty(payLoad.title) ? "New Order Received" : payLoad.title;
        const mBody = isEmpty(payLoad.body) ? "Home Delivery" : payLoad.body;
        const billNo = isEmpty(payLoad.billNo) ? "Bill-0O-224" : payLoad.billNo;

        printDebugLog('Received message: ORDERRECEIVED', obj);
        printDebugLog('Received message: ORDERRECEIVED', mTitle);
        printDebugLog('Received message: ORDERRECEIVED', mBody);
        printDebugLog('Received message: ORDERRECEIVED', billNo);

        var options = {
          body: mBody,
          icon: 'https://www.snpnextgen.com/static/media/snp_logo-1.5f6346a2.png?auto=compress&cs=tinysrgb&dpr=1&w=500',
          dir: 'ltr',
        };


        //https://www.npmjs.com/package/react-notifications
        NotificationManager.info(mTitle, <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{mBody}</p>, 18000, () => {
          navigate("/dashboard/billentry", { state: { tableProps:{}, sBillNo: billNo, billType: InternalBillType.Home } });
        });
        EnableSound();
        // const audio = new Audio(wavFile);
        // audio.play();

        //Old code
        // navigator.serviceWorker.register('sw.js');
        // Notification.requestPermission(function(result) {
        //   if (result === 'granted') {
        //     console.log('showNotification granted');
        //     navigator.serviceWorker.getRegistrations().then(function(registrations) {
        //       registrations[0].showNotification(mTitle, options);
        //     });

        //   } else 
        //     console.log('showNotification disabled');
        // });

        // Notification request
        if (Notification.permission === 'default') {
          Notification.requestPermission().then(permission => {
            if (permission !== 'granted') {
              console.error('Notification permission not granted');
            }
          });
        }
        console.debug("push-notif-title: ", mTitle);
        console.debug("push-notif-data: ", options);
        //Show notication and pass data to service worker
        if (navigator.serviceWorker) {
          navigator.serviceWorker.ready.then(registration => {
            registration.showNotification(mTitle, options);
          });
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    //e.preventDefault();
    //console.log("handleKeyDown",storeActiveShopHash.cartType);
    if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyE') {
      navigate("/dashboard/employee");
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyC') {
      navigate("/dashboard/cateogory");
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyR') {
      navigate("/dashboard/itementry");
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyB') {
      if (storeActiveShopHash.cartType === CartDets.Resto) {
        navigate("/dashboard/billentry", { state: { tableProps:{}, sBillNo:"", billType: InternalBillType.TakeAway } });
      } else {
        navigate("/dashboard/billentry");
      }
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyP') {
      navigate("/dashboard/profile");
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyA') {
      navigate("/dashboard/accounting");
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyS' && storeActiveShopHash.cartType !== CartDets.Resto) {
      setComponentIndex(DashboardComponent.SEARCHDIALOG)
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyL') {
      setComponentIndex(DashboardComponent.LOCKDIALOG)
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyQ' && storeActiveShopHash.cartType === CartDets.Resto) {
      navigate("/dashboard/billhandler", { state: { quickBillCheck: true } });
    }
    resetInactivityTimer();
  };

  const handleMouseMove = () => {
    //console.log("handleMouseMove");
    resetInactivityTimer();
  };

  const resetInactivityTimer = () => {
    if (!inactive && CART_INITIAL_STATE.defInactitivityTime !== -1) {
      clearTimeout(inactivityTimer);
      startInactivityTimer();
    }
  };

  // Start the inactivity timer
  const startInactivityTimer = () => {
    //defInactitivityTime it should come greater than 1 minute
    const inactivityTime = CART_INITIAL_STATE.defInactitivityTime * 60 * 1000;
    inactivityTimer = setTimeout(() => {
      setInactive(true);
      setComponentIndex(DashboardComponent.LOCKDIALOG);
    }, (inactivityTime)); // Set the duration of inactivity in milliseconds (e.g., 5000 = 5 seconds)
  };

  useEffect(() => {
    printDebugLog("location.state",location.state);
    if (location.state?.showFeatureInactiveDialog) {
      showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
    } else if (location.state?.logout) {
      setComponentIndex(DashboardComponent.LOGOUT);
    }
  }, [location.state]);

  //https://blog.bitsrc.io/how-to-use-desktop-notifications-with-react-122536954dc2
  useEffect(() => {
    setOpen(false);
    checkPersistence();
    Print_Log.DEBUG_ENABLED = test_environment_mode ? true : getQueryParam('debug') === '1';
    printInfoLog("DEBUG_ENABLED in Prod", Print_Log.DEBUG_ENABLED);
    inititateNotification();
  }, []);

  useEffect(() => {
    const keepAlive = () => {
      axios.get(storeApiUrl.BACKEND_BASE_URL + 'admin_snp_cart/a2z-heartbeat')
        .then(response => {
          printDebugLog('snp ping Response:', response.data);
        })
        .catch(error => {
          printInfoLog('snp ping Error:', error);
        });
    };

    const intervalId = setInterval(keepAlive, KEEP_ALIVE_INTERVAL);
    return () => {
      clearInterval(intervalId);
    }
  }, [storeApiUrl]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    if (CART_INITIAL_STATE.defInactitivityTime !== -1)
      window.addEventListener('mousemove', handleMouseMove);
    return () => {
      console.log("event listener removed")
      window.removeEventListener("keydown", handleKeyDown)
      if (CART_INITIAL_STATE.defInactitivityTime !== -1)
        window.removeEventListener('mousemove', handleMouseMove);
    }
  }, []);

  useEffect(() => {
    const socket = new websocket.w3cwebsocket(`${storeApiUrl.WSBACKENED_BASE_URL}?wsApiToken=${CART_INITIAL_STATE.apiToken}`);
    socket.onopen = () => {
      console.info('WebSocket connected');
    };
    socket.onmessage = (event) => {
      console.log('Received message:', event.data);
      if (!isEmpty(event.data) && event.data.includes(':')) {
        const obj = JSON.parse(event.data);
        handleSocketMessage(obj);
      }
    }
    socket.onclose = () => {
      console.log('WebSocket disconnected');
    };
    return () => {
      socket.close();
    }
  }, [storeApiUrl]);

  useEffect(() => {
    if (CART_INITIAL_STATE.firstTimeLogin)
      setComponentIndex(DashboardComponent.PASSWORDDIALOG);
    if (!CART_INITIAL_STATE.loggedIn)
      setLogoutUser(true);
  }, [storeActiveShopHash]);

  const renderPasswordDialogContent = () => {
    return (
      <Grid container spacing={1} sx={{ display: "flex", justifyContent: "center", width: "250px" }}>
        {
          CART_INITIAL_STATE.firstTimeLogin && <Grid item md={12}>
            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "14px" }}>First Login! Please Change the Password</p>
          </Grid>
        }

        <Grid item xs={12}>
          <TextField
            disabled
            id="userName"
            label="User Name"
            name="userName"
            defaultValue={CART_INITIAL_STATE.userName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="oldPassWord"
            label="Input Old Password"
            name="oldPassWord"
            type="password"
            onChange={(e) => setPasswordDialog(prevs => ({
              ...prevs,
              oldPassWord: e.target.value,
            }))}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="newPassWord"
            label="Input New Password"
            name="newPassWord"
            type="password"
            onChange={(e) => setPasswordDialog(prevs => ({
              ...prevs,
              newPassWord: e.target.value,
            }))}
          />
        </Grid>
        {!isEmpty(passwordDialog.message) &&
          <Grid item xs={12}>
            <p style={{
              color: "red",
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
              fontSize: "11px"
            }}>{passwordDialog.message}</p>
          </Grid>
        }
      </Grid>
    );
  };

  const renderPasswordDialogAction = () => {
    return (
      <>
        <SnpButton variant="contained" color="primary" onClick={handleChangePwd}>
          Change Password
        </SnpButton>
        <DismissButton variant="contained" color="primary" onClick={() => setPasswordDialog(INITIAL_MESSAGE)}>
          Dismiss
        </DismissButton>
      </>
    );
  };

  const getProductDetailsUI = () => {
    return (
      <Paper elevation={10}
        autoHeight
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#c0c5ce",
          boxShadow: 1,
          borderRadius: 4,
          padding: 2,
        }} >
        <div>
          <Typography
            variant="p"
            style={{ fontSize: "14px" }}
            component="div">
            <span style={{ color: "blue", fontWeight: "bold" }}>Item Name</span>
            <span style={{ margin: "0 4px" }}>:</span>
            <span style={{ color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{stockDetails.details}</span>
          </Typography>
          <Typography
            variant="p"
            style={{ fontSize: "14px" }}
            component="div">
            <span style={{ color: "blue", fontWeight: "bold" }}>Type</span>
            <span style={{ margin: "0 4px" }}>:</span>
            <span style={{
              color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word'
            }}>{stockDetails.type}</span>
          </Typography>
          <Typography
            variant="p"
            style={{ fontSize: "14px" }}
            component="div">
            <span style={{ color: "blue", fontWeight: "bold" }}>Rack No</span>
            <span style={{ margin: "0 4px" }}>:</span>
            <span style={{
              color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word'
            }}>{stockDetails.rack_no}</span>
          </Typography>
          <Typography
            variant="p"
            style={{ fontSize: "14px" }}
            component="div">
            <span style={{ color: "blue", fontWeight: "bold" }}>Available Stock</span>
            <span style={{ margin: "0 4px" }}>:</span>
            <span style={{
              color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word'
            }}>{stockDetails.liveStockData || "No Stock Found.Add new stock"}</span>
          </Typography>
          {/* {
            stockManagement.liveStockOnlineData &&
            <Typography
              variant="p"
              style={{ fontSize: "14px" }}
              component="div">
              <span style={{ color: "blue", fontWeight: "bold" }}>Online Stock</span>
              <span style={{ margin: "0 4px" }}>:</span>
              <span style={{
                color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word'
              }}>{stockDetails.liveStockOnlineData}</span>
            </Typography>
          } */}
          <Typography
            variant="p"
            style={{ fontSize: "14px" }}
            component="div">
            <span style={{ color: "blue", fontWeight: "bold" }}>Expired Stock</span>
            <span style={{ margin: "0 4px" }}>:</span>
            <span style={{
              color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word'
            }}>{stockDetails.expiryLiveStockData || "0"}</span>
          </Typography>
        </div>
      </Paper>
    );
  }

  const renderSearchContent = () => {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12} sm={12} lg={12}>
            <Autocomplete id="search-select"
              options={listStock}
              autoHighlight
              variant="filled"
              size="small"
              onChange={handleUpdateStockDetails}
              fullWidth
              onBlur={(e) => setListStock([])}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.details}
              defaultValue={stockDetails}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                >
                  {option.details}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Here"
                  onChange={handleSearch}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>
          {
            isGetStockDetails &&
            <Grid item md={12} xs={12} sm={12} lg={12}>
              {getProductDetailsUI()}</Grid>
          }
        </Grid>
      </div>
    );
  };



  const renderSearchDialogAction = () => {
    return (
      <Box sx={{ display: "flex", width: "100%", maxWidth: "none" }}>
        <Grid item xs={12}>
          <SnpButton id="gsd"
            name="gsd" disabled={!listDataAvail} variant="contained" color="primary" onClick={handleSearch} sx={{
              mx: 2
            }}>
            Get Stock Details
          </SnpButton>
          <SnpButton id="lse"
            name="lse" disabled={!listDataAvail} variant="contained" color="primary" onClick={handleSearch} sx={{
              mr: 2
            }}>
            Live Stock Entry
          </SnpButton>
          <SnpButton id="sb"
            name="sb" disabled={!listDataAvail} variant="contained" color="primary" onClick={handleSearch} sx={{
              mr: 2
            }}>
            Start Billing
          </SnpButton>
        </Grid>
      </Box>
    );
  };


  const renderLockIcon = () => {
    return (
      <Avatar style={{ backgroundColor: "#1bbd7e" }}>
        <LockOutlinedIcon />
      </Avatar>
    );
  };
  const renderLockDialogContent = () => {
    return (
      <div>
        <Grid container spacing={1} sx={{ display: "flex", justifyContent: "center", width: "250px" }}>
          <Grid item md={2} xs={2}>
            {renderLockIcon()}
          </Grid>
          <Grid item md={10} xs={10}>
            <Typography variant="p" color="#02056e" fontWeight='bold' fontSize={25}>
              {CART_INITIAL_STATE.userName}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              required
              id="oldPassWord"
              label="Enter Password"
              name="oldPassWord"
              type="password"
              size="small"
              onChange={(e) => setLockDialog(prevs => ({
                ...prevs,
                oldPassWord: e.target.value,
              }))}
              onKeyDown={handleSignInKeyDown}
            />
          </Grid>
        </Grid>

        {!isEmpty(lockDialog.message) &&
          <Grid item xs={12}>
            <p style={{
              color: "red",
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
              fontSize: "11px"
            }}>{lockDialog.message}</p>
          </Grid>
        }
      </div>
    );
  };

  const renderLockDialogAction = () => {
    return (
      <>
        {
          !expiryDialog.show && <SnpButton variant="contained" size="small" color="primary" onClick={signIn}>
            Sign In
          </SnpButton>
        }
        <DismissButton variant="contained" size="small" color="primary" onClick={(e) => setComponentIndex(DashboardComponent.LOGOUT)}>
          Sign Out
        </DismissButton>
      </>
    );
  }

  const renderValidityExpiryContent = () => {
    return (
      <Grid container spacing={1} sx={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12}>
          <center>
            <Typography
              variant="p"
              component="div"
              style={{ color: "#520607", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '28px' }}
            >
              {expiryDialog.message}
            </Typography>
          </center>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="demo-simple-select-label"
            variant="filled"
            size="small"
            autoHighlight
            options={CART_INITIAL_STATE.listShopHash}
            onChange={updateCartValue}
            getOptionLabel={(option) => getKeyByValue(CartDets, option)}
            defaultValue={storeActiveShopHash}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
              >
                {
                  storeActiveShopHash.id === option.id ?
                    <Typography textAlign="center" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', color: "#2f1160", fontWeight: 'bold' }}>{getKeyByValue(CartDets, option)}</Typography>
                    : <Typography textAlign="center" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{getKeyByValue(CartDets, option)}</Typography>
                }
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Shop Type"
                inputProps={{
                  ...params.inputProps,
                }}
                style={{ width: "100%" }}
              />
            )}
          />

        </Grid>
      </Grid>
    );
  };

  return (
    loggedOut ? offline_enable ? offlineLogout() : <Navigate replace to="/login" /> :
      <>
        <NotificationContainer />
        <NotificationSound message={notificationSound.message} show={notificationSound.show} />
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          {loader && <Loader />}
          <Navbar open={open}
            toggleDrawer={toggleDrawer}
            handleLoader={handleLoader}
            setComponentIndex={setComponentIndex}
            throwValidityError={handleThrowValidityErrorByShop}
          />
          <Sidebar
            open={open}
            toggleDrawer={toggleDrawer}
            setComponentIndex={setComponentIndex}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              overflow: "hidden",

            }}
          >
            <Container maxWidth="none" sx={{ mt: 9, mb: 6, backgroundColor: "#12h56" }}>
              <Routes>
                <Route path="/" element={getDefaultScreen()} />
                <Route path="/home" element={<FeatureGuard
                  feature={[OWNERDASHBOARD, REPGEN]}
                  Component={OwnerDashboard} />
                } />
                <Route path="/employee" element={<FeatureGuard
                  feature={EMPMGMT}
                  Component={Employee}
                  showDialogBoxWithPaper={showDialogBox}
                />
                } />
                <Route path="/cateogory" element={<FeatureGuard
                  feature={[INVSTOCKMGMT, FOODITEMPRODUCTMGMT]}
                  Component={Category}
                  showDialogBoxWithPaper={showDialogBox}
                />
                } />
                <Route path="/itementry" element={<FeatureGuard
                  feature={[INVSTOCKMGMT, FOODITEMPRODUCTMGMT]}
                  Component={storeActiveShopHash.cartType === CartDets.Resto ? RestoProductStock : ProductEntry}
                />
                } />
                <Route path="/supplier" element={<FeatureGuard
                  feature={SUPMGMT}
                  Component={SupplierInfo}
                  showDialogBoxWithPaper={showDialogBox}
                />
                } />
                <Route path="/stockentry" element={
                  <FeatureGuard
                    feature={STOCKMGMT}
                    Component={storeActiveShopHash.cartType === CartDets.Resto ? RestoStockInformation : StockEntry}
                    showDialogBoxWithPaper={showDialogBox}
                  />
                }
                />
                <Route path="/customunit" element={<FeatureGuard
                  feature={CUSTOMUNITMGMT}
                  Component={Conversion}
                  showDialogBoxWithPaper={showDialogBox}
                />
                } />
                <Route path="/billhandler" element={<FeatureGuard
                  feature={BILLMGMT}
                  Component={storeActiveShopHash.cartType === CartDets.Resto ? BillingTable : BillConnector}
                  showDialogBoxWithPaper={showDialogBox}
                />
                } />
                <Route path="/theme" element={<FeatureGuard
                  feature={THEMEMGMT}
                  Component={ThemeManager}
                  showDialogBoxWithPaper={showDialogBox}
                />
                } />
                <Route path="/customer" element={<FeatureGuard
                  feature={CUSTOMERMGMT}
                  Component={Customer}
                  showDialogBoxWithPaper={showDialogBox}
                />
                } />
                <Route path="/profile" element={<Profile throwValidityError={handleThrowValidityErrorByShop} />} />
                <Route path="/accounting" element={<FeatureGuard
                  feature={ACTMGMT}
                  Component={Accounting}
                  showDialogBoxWithPaper={showDialogBox}
                />
                } />
                <Route path="/tableconfig" element={storeActiveShopHash.cartType === CartDets.Resto ? <BookTable /> : null} />
                <Route path="/billentry" element={<FeatureGuard
                  feature={BILLMGMT}
                  Component={storeActiveShopHash.cartType === CartDets.Resto ? RestoBillingModule : MartBilling}
                />
                } />
                <Route path="/showbills" element={<FeatureGuard
                  feature={BILLMGMT}
                  Component={ShowBills}
                />
                } />
                <Route path="/comboentry" element={<FeatureGuard
                  feature={[COMBOMGMT, COMBOFOODITEMGMT]}
                  Component={ComboOffer}
                />
                } />
                <Route path="/configuration" element={<FeatureGuard
                  feature={CUSTOMIZEDCONFIG}
                  Component={Configuration}
                />
                } />
                <Route path="/trackrider" element={<FeatureGuard
                  feature={CUSTOMERAPP}
                  Component={RiderDashBoard}
                />
                } />
                <Route path="/itemsaledashboard" element={<FeatureGuard
                  feature={SHOWITEMWISESALES}
                  Component={ItemSaleDashboard}
                />
                } />
                <Route path="/returnwastebill" element={<FeatureGuard
                  feature={RETURNWASTEMGMT}
                  Component={ReturnWasteBill}
                />
                } />
                <Route path="/receipe" element={<FeatureGuard
                  feature={RECPMGMT}
                  Component={Receipe}
                />
                } />
                <Route path="/help" element={<HelpModule/>} />
              </Routes>
            </Container>
          </Box>
        </Box>
        <ConfirmDialog isOpen={confirmDialog.showDialog}
          title={confirmDialog.title}
          subTitle={confirmDialog.subTitle}
          negativeAction={confirmDialog.negativeAction}
          positiveReq={confirmDialog.positiveReq}
          onClose={() => setConfirmDialog(INITIAL_DIALOG_CONTENT)}
          iconReq={confirmDialog.iconReq}
          onCancel={confirmDialog.onHandleCancel}
          cancelExtra={confirmDialog.cancelConfirmExtra}
          outsideClickedDismiss={confirmDialog.outsideClickedConfirmDismiss}
          onSubmit={() => setComponentIndex(DashboardComponent.LOGOUT)}
        />
        <DialogBox
          showDialog={passwordDialog.show}
          title="Change Password"
          onClose={() => setPasswordDialog(INITIAL_MESSAGE)}
          dialogContent={renderPasswordDialogContent()}
          dialogActions={renderPasswordDialogAction()}
        />
        <DialogBox
          showDialog={searchDialog.show}
          title="Search By Item or Brand Name"
          onClose={() => setSearchDialog(INITIAL_MESSAGE)}
          dialogContent={renderSearchContent()}
          dialogActions={renderSearchDialogAction()}
        />
        <DialogBox
          showDialog={lockDialog.show}
          title="Unlock"
          outsideClickedDismiss={false}
          onClose={() => setLockDialog(INITIAL_MESSAGE)}
          dialogContent={renderLockDialogContent()}
          dialogActions={renderLockDialogAction()}
        />
        <DialogBox
          showDialog={expiryDialog.show}
          title="Validity Expired"
          outsideClickedDismiss={false}
          onClose={() => setExpiryDialog(INITIAL_MESSAGE)}
          dialogContent={renderValidityExpiryContent()}
          dialogActions={renderLockDialogAction()}
        />
      </>
  )
}