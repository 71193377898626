import React, { useLayoutEffect, useState } from "react";
import CustomDataGrid from "../global/CustomDataGrid";
import {
  Grid,
  TextField,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Checkbox,
  ListItemButton,
  Typography,
  Paper,
  Card,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import List from "@mui/material/List";
import Autocomplete from "@mui/material/Autocomplete";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListIcon from '@mui/icons-material/List';
import InventoryIcon from '@mui/icons-material/Inventory';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import {
  GET_CONVERSIONTYPE_DATA,
  GET_CATEOGORY_DATA,
  GET_PRODUCT_DATA,
  SET_PRODUCT_DATA,
  getPreUrlAccordingToCart,
  SUCCESS,
  ItemStatus,
  CART_INITIAL_STATE, isRoleAllowed, INVENTORYCREATE, CartDets, DELETEINVENTORY, FAILED,  SESSIONEXPIRED,
  printDebugLog,
  printInfoLog,
  isFeatureAllowed,
  STOCKMGMT,
  STOCKVIEW,
  isEmpty,
  SizeType,
  InventoryType,
  PersonType
} from "../../constants/constant";
import ImageUploadService from "../../service/imageUploadService";
import axios from "axios";
import DialogBox from "../global/DialogBox";
import { useSelector } from "react-redux";
import "./Product.css";
import { snpAxios } from '../global/api';
import SnpComponent from "../global/SnpComponent";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import { useNavigate } from "react-router-dom";
import ThreeDText from "../global/ThreeDText";
import EnterButton from "../global/EnterButton";
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../global/loader/Loader";

const INITIAL_CATEOGORY = {
  catgId: "",
  catgName: "",
};

export default function ProductEntry() {
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const navigate = useNavigate();
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isEditProduct, setEditProduct] = useState(false);
  const [preDialog, setPreDialog] = useState(false);
  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [imageSelectedError, setImageSelectedError] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedCategory, setSelectedcategory] = useState(INITIAL_CATEOGORY);
  const [selectedImage, setSelectedImage] = useState('');
  const INITIAL_SNP_COMPONENT = {
    showDialog: false,
    outSideClickDismiss: true,
    title: "",
    subTitle: "",
    negativeAction: "",
    positiveAction: "",
    positiveReq: false,
    isLoader: false,
    iconReq: false,
    iconContent: "",
    snackBarOpen: false,
    snackBarMsg: "",
    cancelExtra: false,
    negativeReq: false,
    requestType: 0,
    handleSnpSubmit: null,
    handleSnpCancel: null,
    gui: "",
  };

  const INITIAL_SINGLE_PRODUCT = {
    catgId: "",
    catgName: "",
    itemStatus: ItemStatus.ACTIVE,
    prdName: "",
    composition: [],
    brandName: "",
    prdId: "",
    cnvId: "",
    hsnCode: "",
    rack_no: "",
    unitName: "",
    unitCount: 1,
    unitRestrictions: false,
    smallUnitName: "",
    smallUnitCount: 0,
    productImage: [],
    allowedDelete: false,
    itemStatusStr:"",
    itemShortCode:"",
    sizeTypeStr:"",
    sizeType: SizeType.None,
    inventoryTypeStr:"",
    inventoryType:InventoryType.Groceries,
    personTypeStr:"",
    personType:PersonType.None,
  };

  const INITIAL_CONVERSION_SINGLE_DATA = {
    id: "",
    unitName: "",
    unitRestrictions: false,
    smallUnitName: "",
    smallUnitCount: 1,
    cnvDetails:"",
  };

  const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
  const [productSingleData, setSingleProductData] = useState(INITIAL_SINGLE_PRODUCT);
  const [createUnit, setCreateUnit] = useState(false);
const [dialogLoader, setDialogLoader] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [conversionSingleData, setConversionSingleData] = useState(INITIAL_CONVERSION_SINGLE_DATA);
  const [conversionTypeData, setConversionTypeData] = useState([]);
  const [listComposition, setListComposition] = useState([]);
  
  const handleConversionChange = (event, option) => {
    if (option != null) {
      // console.log("handleConversionChange", option);
      if (option.id === "cr") {
        setConversionSingleData(INITIAL_CONVERSION_SINGLE_DATA);
        setCreateUnit(true);
      } else {
        if (createUnit)
          setCreateUnit(false);
        setConversionSingleData(option);
      }
    } else {
      setCreateUnit(false);
      setConversionSingleData(option);
    }
  };

  // const getCustomUnitDetails = (option) => {
  //   let data = "";
  //   if (option.id === 'cr')
  //     data = option.unitName;
  //   else {
  //     if (option.unitName) {
  //       if (option.unitRestrictions) {
  //         if (option.smallUnitCount > 0 && option.smallUnitName)
  //           data = "Only " + option.unitName + "(" + option.smallUnitCount + " " + option.smallUnitName + ")";
  //         else
  //           data = "Only " + option.unitName;
  //       } else {
  //         data = option.unitName + "=" + option.smallUnitCount + " " + option.smallUnitName;
  //       }
  //     }
  //   }
  //   return data;
  // };
  const handleCoversionUpdate = (event) => {
    const { name, value } = event.target;
    //console.log("handleCoversionUpdate", name, value);
    if (name === 'unitRestrictions') {
      const previousCheckedValue = conversionSingleData.unitRestrictions;
      setConversionSingleData({
        ...conversionSingleData,
        unitRestrictions: !previousCheckedValue,
      });
    } else if (name === 'smallUnitCount') {
      if (value === "" || /^[0-9]*$/.test(value)) {
        setConversionSingleData({
          ...conversionSingleData,
          smallUnitCount: Number(value),
        });
      }
    } else {
      if (value === "" || /^[a-zA-Z]*$/.test(value)) {
        setConversionSingleData({
          ...conversionSingleData,
          [name]: value,
        });
      }
    }
  };



  const handleSelectChange = (event, catg) => {
    //console.log("handleSelectChange", catg);
    if (catg != null) {
      setSelectedcategory(catg);
    } else {
      setSelectedcategory(INITIAL_CATEOGORY)
    }
  };
  const handleInputChange = (event) => {
    //console.log("handleInputChange",event);
    const { name, value } = event.target;

    if (name === 'imgPath') {
      setUploadedDocument(event.target.files[0]);
      if (event.target.files[0]) {
        setImageSelectedError(false);
        const reader = new FileReader();
        const url = reader.readAsDataURL(event.target.files[0]);
        //console.log('VN Data', reader.result,url)
        printDebugLog("handleInputChange", url);
        reader.onloadend = function (e) {
          //console.log('VN ssData', reader.result,url)
          setSelectedImage(reader.result)
        }
      } else {
        setSelectedImage('')
        setImageSelectedError(true);
      }
    } else {
      setSingleProductData({
        ...productSingleData,
        [name]: value,
      });
    }
    //console.log("setSingleProductData-> ",productSingleData);
  };

  const handleImageUpload = async () => {
    setFormSubmitted(true);
    printDebugLog("handleImageUpload",productSingleData);
    if (!isValid()) {
      return;
    }
    let isError = false;
    // if (!uploadedDocument || uploadedDocument.size > 5242880 || !(uploadedDocument.type.includes("image"))) {    //5248256
    //   setImageSelectedError(true);
    //   isError = true;
    // }
    if (!uploadedDocument) {
      isError = true;
    }
    if (!isError) {
      setDataLoaded(false);
      setShowDialog(false);
      setFormSubmitted(false);
      let formData = new FormData();
      formData.append('fnm', CART_INITIAL_STATE.apiToken);
      formData.append("file", uploadedDocument);
      await ImageUploadService.upload(storeApiUrl.BACKEND_BASE_URL, getPreUrlAccordingToCart(storeActiveShopHash.cartType), formData).then(response => {
        if (response.data.folderName) {
          printDebugLog("folderName", response.data.folderName);
          if (!productSingleData.productImage)
            productSingleData.productImage = [];
          productSingleData.productImage.push(response.data.folderName);
          handleProductEntry(ItemStatus.ACTIVE);
        } else {
          printInfoLog("response.data", response.data);
        }

      }
      ).catch(error => {
        handleProductEntry(ItemStatus.ACTIVE);
        printInfoLog("handleImageUpload = > ", error);
        // window.location.href = "#/error";
      });
    } else {
      handleProductEntry(ItemStatus.ACTIVE);
    }
  };

  // const getColumnsCoversion = (val) => {
  //   if ((val.unitCount === val.smallUnitCount) && (val.unitName && val.smallUnitName)) {
  //     return val.unitName;
  //   } else {
  //     return val.unitName + "(" + val.smallUnitCount + " " + val.smallUnitName + ")";
  //   }
  // }

  const columns = [
    {
      field: "productImage",
      headerName: "Icon",
      width: 100,
      renderCell: (rowData) => (
        <div>
          {
             isEmpty(rowData.row.productImage) ? 
             <ImageNotSupportedIcon style={{ height: "80px", width: "80px", borderRadius: "20%" }}/> : <img
             alt=""
             style={{ height: "80px", width: "80px", borderRadius: "20%" }}
             src={rowData.row.productImage && storeApiUrl.BACKEND_BASE_URL + "files/" + rowData.row.productImage[0]}
           ></img>
          }
          
        </div>
      ),
    },
    { field: 'catgName', headerName: 'Category', width: 150 },
    { field: 'inventoryTypeStr', headerName: 'Type', width: 140 },
    {
      field: 'prdName', headerName: 'Item', width: 200
    },
    {
      field: 'brandName', headerName: 'Brand', width: 200
    },
    {
      field: 'cnvDetails', headerName: 'Unit Details', width: 250,
    },
    {
      field: 'itemStatusStr', headerName: 'Status', width: 120},
    {
      field: "edit",
      headerName: "",
      width: 50,
      renderCell: (cellValues) => {
        return (
          <EditIcon
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          >
          </EditIcon>
        );
      }
    }
  ];

  const compositionColumns = [
    {
      field: "productImage",
      headerName: "Icon",
      width: 120,
      renderCell: (rowData) => (
        <div>
          <img
            alt=""
            style={{ height: "80px", width: "80px", borderRadius: "40%" }}
            src={storeApiUrl.BACKEND_BASE_URL + "files/" + rowData.row.productImage[0]}
          ></img>
        </div>
      ),
    },
    { field: 'catgName', headerName: 'Category Name', width: 200 },
    {
      field: 'productName-brandName', headerName: 'Product Details', width: 230,
      renderCell: (val) => {
        return val.row.prdName + "-" + val.row.brandName;
      }
    },
    { field: 'composition', headerName: 'Composition', width: 280 },
    {
      field: 'itemStatus', headerName: 'Status', width: 150,
      renderCell: (val) => {
        return (val.row.itemStatus === 1) ? 'Active' : 'Inactive';
      }
    },
    {
      field: "edit",
      headerName: "",
      width: 50,
      renderCell: (cellValues) => {
        return (
          <EditIcon
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          >
          </EditIcon>
        );
      }
    }
  ];


  const handleCellClick = (param, event) => {
    printDebugLog("setSingleproductData : ", event.row);
    setSelectedcategory({
      catgId: event.row.catgId,
      catgName: event.row.catgName
    });
    setConversionSingleData({
      ...conversionSingleData,
      id: event.row.cnvId,
      unitName: event.row.unitName,
      unitCount: event.row.unitCount,
      unitRestrictions: event.row.unitRestrictions,
      smallUnitName: event.row.smallUnitName,
      smallUnitCount: event.row.smallUnitCount,
      cnvDetails:event.row.cnvDetails,
    });
    setSingleProductData({
      ...productSingleData,
      itemStatus: event.row.itemStatus,
      prdName: event.row.prdName,
      brandName: event.row.brandName,
      prdId: event.row.prdId,
      productImage: event.row.productImage,
      catgId: event.row.catgId,
      catgName: event.row.catgName,
      cnvId: event.row.id,
      hsnCode: event.row.hsnCode,
      rack_no: event.row.rack_no,
      unitName: event.row.unitName,
      unitCount: event.row.unitCount,
      unitRestrictions: event.row.unitRestrictions,
      smallUnitName: event.row.smallUnitName,
      smallUnitCount: event.row.smallUnitCount,
      composition: isEmpty(event.row.composition) ? [] : event.row.composition,
      allowedDelete: event.row.allowedDelete,
      itemShortCode:event.row.itemShortCode,
      inventoryType:event.row.inventoryType,
      sizeType:event.row.sizeType,
      personType:event.row.personType,
    })
    setPreDialog(true);
    setShowDialog(true);
    setEditProduct(true);
  };


  const clearProductData = () => {
    setCreateUnit(false);
    setConversionSingleData(INITIAL_CONVERSION_SINGLE_DATA);
    setSelectedcategory(INITIAL_CATEOGORY)
    setSingleProductData(INITIAL_SINGLE_PRODUCT);
    setSelectedImage('');
  }

  const getConversionData = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_CONVERSIONTYPE_DATA;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        createNew: true,
      }
    };
    //   console.log("verifyCredentials => ",data);
    snpAxios.post(
      catgUrl,
      data
    )
      .then((response) => {
        if (response.data.status === SUCCESS) {
          setConversionTypeData(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        printInfoLog("error : ", error);
        //window.location.href = "#/error";
      });
  };

  const handleDeleteImage = (index) => {
    let path = productSingleData.productImage[index];
    let cUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}delete-image-mart?productHash=${productSingleData.prdId}&path=${path}`;
    let data = {};
    setDialogLoader(true);
    printDebugLog("handleDeleteImage<=", cUrl);
    axios.get(
      cUrl,
      data
    )
      .then((response) => {
        printDebugLog("handleDeleteImage=>", response.data);
        setDialogLoader(false);
        if (response.data.status === SUCCESS) {
          // If item is found, create a new array without it
          if (index !== -1) {
            let images = productSingleData.productImage;
            const updatedItems = [...images.slice(0, index), ...images.slice(index + 1)];
            setSingleProductData({
              ...productSingleData,
              productImage: updatedItems,
            })
            setSnpComponent({
              ...snpComponent, snackBarMsg: "Image removed successfull ... Updating Items",
              snackBarOpen: true,
            })
            //setShowDialog(false);
            getProductDetails();

          }
        }
      }).catch((error) => {
        setDialogLoader(false);
        printInfoLog("error : ", error);
        //window.location.href = "#/error";
      });
  }

  const handleProductEntry = async (productStatus) => {
    setDataLoaded(false);
    setShowDialog(false);
    setSelectedImage('');
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SET_PRODUCT_DATA;
    let filledData = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        catgId: selectedCategory.catgId,
        catgName: selectedCategory.catgName,
        composition: productSingleData.composition,
        itemStatus: Number(productStatus),
        prdName: productSingleData.prdName,
        brandName: productSingleData.brandName,
        hsnCode: productSingleData.hsnCode,
        rack_no: productSingleData.rack_no,
        productImage: productSingleData.productImage,
        prdId: productSingleData.prdId,
        cnvId: conversionSingleData.id,
        unitName: conversionSingleData.unitName,
        smallUnitCount: Number(conversionSingleData.smallUnitCount),
        unitCount: Number(conversionSingleData.unitCount),
        smallUnitName: conversionSingleData.smallUnitName,
        unitRestrictions: conversionSingleData.unitRestrictions,
        inventoryType:Number(productSingleData.inventoryType),
        sizeType:Number(productSingleData.sizeType),
        personType:Number(productSingleData.personType),
        itemShortCode:productSingleData.itemShortCode,
      }
    };
    //console.log("handleProductEntry => ", filledData);
    snpAxios.post(
      catgUrl,
      filledData
    ).then(response => {
      printDebugLog("responses => ", response.data);
      if (response.data.status === SUCCESS) {
        getConversionData();
        getProductDetails();
      } else {
        setDataLoaded(true);
      }
    }).catch(error => {
      printInfoLog("error : ", error);
      // window.location.href = "#/error";
      setDataLoaded(true);
    });
  };
  
  const deleteInventory = async (prdId) => {
    handleDialogClose();
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + DELETEINVENTORY + "?deleteId=" + prdId + "&token=" + CART_INITIAL_STATE.apiToken
    +"&shopHash="+storeActiveShopHash.id;
    printDebugLog("deleteInventory => ", catgUrl);
    setDataLoaded(false);
    let data = {
    };
    axios.get(
      catgUrl,
      data
    )
      .then((response) => {
        setDataLoaded(true);
        printDebugLog("response deleteInventory = > " + response.data);
        if (response.data.status === SUCCESS) {
          setSnpComponent({
            ...snpComponent,
            snackBarOpen: true,
            snackBarMsg: "Successfully Deleted ",
          });
        } else {
          let msg = "";
          if (response.data.status === FAILED)
            msg = "Inventory Exist Cannot Delete!";
          else if (response.data.status === SESSIONEXPIRED)
            msg = "Session Expired! Login Again";
          else
            msg = "Failed to Delete (Error:" + response.data.status + ")";
          setSnpComponent({
            ...snpComponent,
            snackBarOpen: true,
            snackBarMsg: msg,
          });
        }
        getProductDetails();
      }).catch((error) => {
        console.log("deleteInventory ", error);
        setDataLoaded(true);
      });
  };

  const getProductDetails = async () => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_PRODUCT_DATA;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
      }
    };
    //  console.log("getProductDetails  => ",data);
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        printDebugLog("response  => ", response.data);
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          setProductData(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        setDataLoaded(true);
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  const handleSearchComposition = (event) => {
    const value = event.target.value;
    //console.log("handleSearchComposition",value)
    if (value.length >= 3) {
      fetchComposition(value);
    }
  };


  const fetchComposition = async (regextStr) => {
    const cartTypeStr = getPreUrlAccordingToCart(storeActiveShopHash.cartType);
    const fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + cartTypeStr + "search-composition?q=" + regextStr + "&m=" + storeActiveShopHash.cartType;
    const data = {};
    axios.get(
      fetchBillUrl,
      data
    )
      .then((response) => {
        printDebugLog("fetchComposition   => ",regextStr, response.data);
        if (response.data !== null) {
          setListComposition(response.data);
        } else {
          setListComposition([]);
        }
      }).catch((error) => {
        console.log("fetchComposition errr ", error);
        setListComposition([]);
      });
  };

  const isValid = () => {
    let isProductSingleDataValid = true;
    printDebugLog("isValid ", createUnit, conversionSingleData);
    let isCnvId = true;
    if (!createUnit) {
      if (!conversionSingleData.id) isCnvId = false;
    }

    if (!productSingleData.prdName || !productSingleData.brandName || !isCnvId) {
      isProductSingleDataValid = false;
    }

    return isProductSingleDataValid;
  }

  const getCartDetails = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_CATEOGORY_DATA;

    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
      }
    };
    //   console.log("verifyCredentials => ",data);
    snpAxios.post(
      catgUrl,
      data
    )
      .then((response) => {
        if (response.data.status === SUCCESS) {
          //setCategoryData(response.data.jcJson.value_2);
          let resp = response.data.jcJson.value_2.map((catg) => {
            // categoryData.push({
            //   label: catg.catgName,
            //   value: catg.id
            // })

            let catgName = catg.catgName
            let catgId = catg.id
            return { catgName, catgId }
          })
          //console.log("Resut : ", resp);
          setCategoryData(resp);
          //console.log(categoryData);
        }
      }).catch((error) => {
        console.log("getCartDetails ", error);
      });
  };
  useLayoutEffect(() => {
    //console.log('Printing Shop Details : ',storeActiveShopHash);
    setCreateUnit(false);
    getCartDetails();
    getConversionData();
    getProductDetails();
  }, [storeActiveShopHash, storeApiUrl]);

  const handleKeyDown = (evt) => {
    printDebugLog("handleKeyDown",evt?.key);
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      printDebugLog("handleKeyDown inputValue",inputValue);
      if (!isEmpty(inputValue)) {
        setSingleProductData(prevs => ({
          ...prevs,
          composition: prevs.composition === undefined ? [inputValue] : [...prevs.composition, inputValue],
        }));
        setInputValue('');
      }
    }
  };

  const handleDelete = (item) => {
    setSingleProductData({
      ...productSingleData,
      composition: productSingleData.composition.filter(i => i !== item),
    });
  };

    const handleItemSearchChange = (event) => {
      if (event)
        setInputValue(event.target.value);
    }

  const handleSelectComposition = (event, newValue, reason, detail) => {
    printDebugLog("Autocomplete", newValue, reason, detail);
    setInputValue('');
    if (reason === 'removeOption') {
      handleDelete(detail.option);
    } else if (reason === 'selectOption') {
       const value = detail.option;
       if (value) {
        setSingleProductData({
          ...productSingleData,
          composition: productSingleData.composition === undefined ? [value] : [...productSingleData.composition, value],
        });
      }
    } else if (reason === 'clear') {
      setSingleProductData({
        ...productSingleData,
        composition: [],
      });
    }
  };


  const renderListDialogContent = () => {
    return (
      <React.Fragment>

        <List id="parent-list-tags">
          <ListItemButton sx={{
            "&:hover": {
              backgroundColor: "#00333e",
              color: "white",
              "& .MuiListItemIcon-root": {
                minWidth: '64px',
                color: "white"
              }
            },
          }} onClick={() => {
            if (isFeatureAllowed(STOCKMGMT) && isRoleAllowed(STOCKVIEW)) {
              navigate("/dashboard/stockentry", { state: { prdId: productSingleData.prdId , isEditStock: 0} });
            } else {
              //console.log("Here");
              setShowDialog(false);
              setSnpComponent({
                ...snpComponent,
                isLoader: false,
                negativeReq: false,
                positiveReq: true,
                positiveAction: "Ok Got it",
                showDialog: true,
                outSideClickDismiss: true,
                cancelExtra: true,
                requestType: 99,
                subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                handleSnpSubmit: () => handleDialogClose(),
              });
            }
          }}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Check All Live Stock Entry" />
          </ListItemButton>
        </List>
        <Divider />
        <List id="parent-list-tags">
          <ListItemButton sx={{
            "&:hover": {
              backgroundColor: "#00333e",
              color: "white",
              "& .MuiListItemIcon-root": {
                minWidth: '64px',
                color: "white"
              }
            },
          }} onClick={() => {
            if (isFeatureAllowed(STOCKMGMT) && isRoleAllowed(STOCKVIEW)) {
              navigate("/dashboard/stockentry", { state: { prdId: productSingleData.prdId , isEditStock: 1} });
            } else {
              //console.log("Here");
              setShowDialog(false);
              setSnpComponent({
                ...snpComponent,
                isLoader: false,
                negativeReq: false,
                positiveReq: true,
                positiveAction: "Ok Got it",
                showDialog: true,
                outSideClickDismiss: true,
                cancelExtra: true,
                requestType: 99,
                subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                handleSnpSubmit: () => handleDialogClose(),
              });
            }
          }}>
            <ListItemIcon>
              <InventoryIcon />
            </ListItemIcon>
            <ListItemText primary="Create New Stock Entry" />
          </ListItemButton>
        </List>
        <Divider />
        <List id="parent-list-tags">
          <ListItemButton sx={{
            "&:hover": {
              backgroundColor: "#00333e",
              color: "white",
              "& .MuiListItemIcon-root": {
                minWidth: '64px',
                color: "white"
              }
            },
          }} onClick={() => {
            if (isRoleAllowed(INVENTORYCREATE)) {
              if (productSingleData.itemStatus === ItemStatus.ACTIVE) {
                handleProductEntry(ItemStatus.INACTIVE);
              } else {
                handleProductEntry(ItemStatus.ACTIVE);
              }
            } else {
              setShowDialog(false);
              setSnpComponent({
                ...snpComponent,
                isLoader: false,
                negativeReq: false,
                positiveReq: true,
                positiveAction: "Ok Got it",
                showDialog: true,
                outSideClickDismiss: true,
                cancelExtra: true,
                requestType: 99,
                subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                handleSnpSubmit: () => handleDialogClose(),
              });
            }
          }}>
            <ListItemIcon>
              {productSingleData.itemStatus === ItemStatus.ACTIVE ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </ListItemIcon>
            <ListItemText primary={"Make " + productSingleData.prdName + "-" + productSingleData.brandName + (productSingleData.itemStatus === ItemStatus.ACTIVE ? " Inactive" : " Active")} />
          </ListItemButton>
        </List>
        <Divider />
        <List id="parent-list-tags">
          <ListItemButton sx={{
            "&:hover": {
              backgroundColor: "#00333e",
              color: "white",
              "& .MuiListItemIcon-root": {
                minWidth: '64px',
                color: "white"
              }
            },
          }} onClick={() => {
            setPreDialog(false);
          }}>
            <ListItemIcon>
              <BorderColorIcon />
            </ListItemIcon>
            <ListItemText primary="Edit Product Details" />
          </ListItemButton>
        </List>
      </React.Fragment>
    )
  };
  

  

  const renderProductEntryDialogContent = () => {
    return (
      <div>
        
        <Grid container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end">
          <Grid item md={6} sm={6} lg={6} xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
            {
                selectedImage &&
                <img
                  alt=""
                  style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                  // src={storeApiUrl.BACKEND_BASE_URL + "files/" + imgv}
                  src={selectedImage}
                />
              }
              <input
                accept="image/*"
                id="imgPath"
                name="imgPath"
                label=""
                type="file"
                placeholder="Restaurant Image(Image should be less than 5Mb.)"
                onChange={handleInputChange}
                style={{ border: "1px solid lightgray", marginRight: "2px", padding: "3px 1px" }}
              />
              
            </div>
          </Grid>
            {
              productSingleData.productImage.length > 0 && productSingleData.productImage.map((item, index) => (
                <Card orientation="horizontal" size="md" key={"Image_" + index} variant="outlined">
                  <img
                    style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                    srcSet={`${storeApiUrl.BACKEND_BASE_URL + "files/" + item}`}
                    src={`${storeApiUrl.BACKEND_BASE_URL + "files/" + item}`}
                    alt={"Image_" + index}
                  />
                  {
                    productSingleData.productImage.length > 1 && <DeleteIcon
                      onClick={() => handleDeleteImage(index)} />
                  }

                </Card>

              ))
            }
        </Grid>
        {imageSelectedError &&
          <Grid container sx={{
            mb: 1,
          }}>
            <Grid item xs zeroMinWidth>
              <Typography variant="p" color="#EE4B2B" noWrap>Please select the Image (image format) having size below 5Mb</Typography>
              <br />
              <Typography variant="p" color="#EE4B2B" noWrap>before uploading.</Typography>
            </Grid></Grid>

        }
        {
              dialogLoader && <Grid xs={12}>
                <center>
                  <Loader /></center>
              </Grid>
            }
        <Paper elevation={10}
          autoHeight
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#e9d9d696",
            boxShadow: 1,
            borderRadius: 4,
            m:2,
            padding: 2,
          }} ><Grid container spacing={1} justifyContent="center"
          alignItems="center" sx={{
            padding: 0.1,
          }}>
          <Grid item md={12} sm={12} lg={12} xs={12}>
            <ThreeDText 
                text="Product Information"
                display=""
                className="three-d-text-container"
                justifyContent="left"
                alignItems="left"/>
          </Grid>
            <Grid item md={12} sm={12} lg={12} xs={12}>
            <FormControl component="fieldset">
                  <FormLabel component="legend">Select Inventory Type</FormLabel>
                  <RadioGroup
                    aria-label="inventoryType"
                    name="inventoryType"
                    value={productSingleData.inventoryType}
                    onChange={handleInputChange}
                    row={true}
                  >
                    {Object.entries(InventoryType).map(([key, value]) => (
                      <FormControlLabel
                        key={key}
                        value={value}
                        control={<Radio />}
                        label={key.replaceAll("_"," ").replaceAll("7","&")}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
            </Grid>
          <Grid item md={6} xs={12} sm={6} lg={6}>
            <TextField
              required
              id="prdName"
              label="Enter Item Name"
              name="prdName"
              variant="filled"
              size="small"
              defaultValue={productSingleData.prdName}
              onChange={handleInputChange}
              fullWidth
              error={formSubmitted && productSingleData.prdName === ""}
              helperText={getErrorMessage('prdName')}
            />
          </Grid>

          <Grid item md={6} xs={12} sm={6} lg={6}>
            <TextField
              id="hsnCode"
              name="hsnCode"
              variant="filled"
              size="small"
              label="Enter HSN Code"
              value={productSingleData.hsnCode}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={6} lg={6} xs={12}>
            <TextField
              required
              id="brand-Name"
              label="Enter Brand Name"
              fullWidth
              size="small"
              name="brandName"
              variant="filled"
              defaultValue={productSingleData.brandName}
              onChange={handleInputChange}
              error={formSubmitted && productSingleData.brandName === ""}
              helperText={getErrorMessage('brandName')}
            />
          </Grid>
          <Grid item md={6} sm={6} lg={6} xs={12} >
            <Autocomplete
              id="cateogory-select"
              options={categoryData}
              autoHighlight
              onChange={handleSelectChange}
              size="small"
              variant="filled"
              fullWidth
              getOptionLabel={(option) => option.catgName}
              defaultValue={selectedCategory}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                >
                  {option.catgName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a category"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} sm={6} lg={6} xs={12}>
            {/* <TextField
              id="composition-Name"
              label={storeActiveShopHash.cartType === CartDets.Pharma ? "Enter Composition" : "Enter SubCateogory"}
              name="composition"
              fullWidth
              size="small"
              value={productSingleData.compositionValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            /> */}
            <Autocomplete id="quick-select"
              multiple
              freeSolo
              options={listComposition}
              autoHighlight
              size="small"
              variant="filled"
              fullWidth
              onChange={handleSelectComposition}
              onBlur={(e) => setListComposition([])}
              getOptionLabel={(option) => option || ''}
              inputValue={inputValue}
              onInputChange={handleItemSearchChange}
              onKeyDown={handleKeyDown}
              defaultValue={isEmpty(productSingleData.composition) ? [] : productSingleData.composition}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={storeActiveShopHash.cartType === CartDets.Pharma ? "Enter Composition" : "Enter SubCateogory"}
                  onChange={handleSearchComposition}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} sm={6} lg={6} xs={12}>
              < Autocomplete
                id="cnv-select"
                size="small"
                disabled={isEditProduct}
                options={conversionTypeData}
                onChange={handleConversionChange}
                fullWidth
                getOptionLabel={(option) => option?.cnvDetails || ''}
                defaultValue={conversionSingleData}
                renderOption={(props, option) => (
                  <Box
                    component="li"

                    {...props}
                  >
                    {option?.cnvDetails}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Custom Unit Type"
                    error={formSubmitted && !productSingleData.cnvId}
                    helperText={getErrorMessage('cnv-select')}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
          {/* <Grid item md={12} sm={12} lg={12} xs={12}>
            {productSingleData.composition && (productSingleData.composition.map(item => (
              <div className="tag-item" key={item}>
                {item}
                <button
                  type="button"
                  className="button"
                  onClick={() => handleDelete(item)}
                >
                  &times;
                </button>
              </div>
            )))}
          </Grid> */}
        </Grid>
        </Paper>
        {
          createUnit &&
          <Paper elevation={10}
            autoHeight
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#d9d2e9",
              boxShadow: 1,
              borderRadius: 4,
              m: 2,
              padding: 2
            }} >
            <Grid container spacing={1} justifyContent="center"
              alignItems="center">
              <Grid item md={12} sm={12} lg={12} xs={12}>
                <ThreeDText
                  text="Enter Unit Details"
                  display=""
                  className="three-d-text-container"
                  justifyContent="left"
                  alignItems="left" />
              </Grid>
              <Grid item md={6} sm={6} lg={6} xs={12}>
                <TextField
                  required
                  id="unitName"
                  label="Enter Unit Name"
                  name="unitName"
                  variant="filled"
                  size="small"
                  value={conversionSingleData.unitName}
                  onChange={handleCoversionUpdate}
                />
              </Grid>
              <Grid item md={6} sm={6} lg={6} xs={12}>
                <FormControlLabel control={<Checkbox checked={conversionSingleData.unitRestrictions}
                  onChange={handleCoversionUpdate} id="unitRestrictions" />} name="unitRestrictions" label="Unit Restrictions" />
              </Grid>
              <Grid item md={6} sm={6} lg={6} xs={12}>
                <TextField
                  required
                  id="smallUnitCount"
                  variant="filled"
                  size="small"
                  label="Enter SmallUnit Count"
                  name="smallUnitCount"
                  value={conversionSingleData.smallUnitCount}
                  onChange={handleCoversionUpdate}
                />
              </Grid>
              <Grid item md={6} sm={6} lg={6} xs={12}>
                <TextField
                  required
                  variant="filled"
                  size="small"
                  id="smallUnitName"
                  label="Enter SmallUnit Name"
                  name="smallUnitName"
                  value={conversionSingleData.smallUnitName}
                  onChange={handleCoversionUpdate}
                />
              </Grid>
            </Grid>
          </Paper>
        }
        <Paper elevation={10}
          autoHeight
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#e37c0f2b",
            boxShadow: 1,
            borderRadius: 4,
            m: 2,
            padding: 2
          }} >
          <Grid container spacing={1} justifyContent="center"
            alignItems="center" sx={{
              padding: 0.1,
            }}>
            <Grid item md={12} sm={12} lg={12} xs={12}>
              <ThreeDText
                text="Advance Configuration"
                display=""
                className="three-d-text-container"
                justifyContent="left"
                alignItems="left"
                />
            </Grid>
            {/* <Grid item md={6} sm={6} lg={6} xs={12}>
              <TextField
                id="itemShortCode"
                name="itemShortCode"
                variant="filled"
                size="small"
                label="Enter Item Short Code"
                value={productSingleData.itemShortCode}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid> */}
            <Grid item md={6} sm={6} lg={6} xs={12}>
              <TextField
                id="rack_no"
                name="rack_no"
                variant="filled"
                size="small"
                label="Enter Rack No(Location)"
                value={productSingleData.rack_no}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item md={6} sm={6} lg={6} xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Select below</FormLabel>
                  <RadioGroup
                    aria-label="personType"
                    name="personType"
                    value={productSingleData.personType}
                    onChange={handleInputChange}
                    row={true}
                  >
                    {Object.entries(PersonType).map(([key, value]) => (
                      <FormControlLabel
                        key={key}
                        value={value}
                        control={<Radio />}
                        label={key.replaceAll("_"," ")}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item md={12} sm={12} lg={12} xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Select Size</FormLabel>
                  <RadioGroup
                    aria-label="sizeType"
                    name="sizeType"
                    value={productSingleData.sizeType}
                    onChange={handleInputChange}
                    row={true}
                  >
                    {Object.entries(SizeType).map(([key, value]) => (
                      <FormControlLabel
                        key={key}
                        value={value}
                        control={<Radio />}
                        label={key.replaceAll("_"," ")}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Select Item Status</FormLabel>
                <RadioGroup aria-label="itemStatus" name="itemStatus" value={productSingleData.itemStatus.toString()} onChange={handleInputChange} row={true}>
                  
                  <FormControlLabel value={ItemStatus.ACTIVE.toString()} control={<Radio />} label="Active" />
                  <FormControlLabel value={ItemStatus.INACTIVE.toString()} control={<Radio />} label="InActive" />

                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          </Paper>
        {
          productSingleData.allowedDelete && <Grid item md={4} xs={6}>
            <center>
            <SnpButton variant="contained" color="bRed" onClick={() => deleteInventory(productSingleData.prdId)}>
              Delete Product
            </SnpButton>
            </center>
          </Grid>
        }

      </div>
    )
  };

  const renderDialogContent = () => {
    return (preDialog ? renderListDialogContent() : renderProductEntryDialogContent());
  }

  const renderDialogAction = () => {
    return (
      <React.Fragment>
        <SnpButton variant="contained" color="primary" onClick={handleImageUpload}>
          {isEditProduct ? "Update" : "Submit"}
        </SnpButton>

        <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
          Cancel
        </DismissButton>
      </React.Fragment>
    );
  }

  const getErrorMessage = (field) => {
    if (!formSubmitted) {
      return;

    } else if (!productSingleData[field]) {
      return 'Field is required';
      // } else if (!selectedCategory.catgId) {
      //   return 'Field is required';
    }
  }

  const handleDialogClose = () => {
    // console.log("handleDialogClose");
    setInputValue('');
    setShowDialog(false);
    clearProductData();
    setEditProduct(false);
    setPreDialog(false);
    setFormSubmitted(false);
    setSnpComponent(INITIAL_SNP_COMPONENT);
  };

  // useEffect(() => {
  //   if (listComposition.length === 0 && productSingleData.composition) {
  //     setListComposition(productSingleData.composition);
  //   }
  // }, [listComposition, productSingleData.composition]);
  


  const renderSnpComponent = () => {
    return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ padding: 10 }}>
            <EnterButton
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              onClick={(event) => {
                if (isRoleAllowed(INVENTORYCREATE)) {
                  setShowDialog(true);
                } else {
                  setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                    negativeReq: false,
                    positiveReq: true,
                    positiveAction: "Ok Got it",
                    showDialog: true,
                    outSideClickDismiss: true,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                    handleSnpSubmit: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                  });
                }
              }}>
              Add New Product
            </EnterButton>
          </div>
        
            <CustomDataGrid
              getRowId={row => row.prdId}
              rows={productData}
              loading={!isDataLoaded}
              columns={storeActiveShopHash.cartType === CartDets.Pharma ? compositionColumns : columns}
              pageSize={7}
              rowsPerPageOptions={[5]}
              autoHeight
            />
          
        </Grid>
      </Grid>
      < DialogBox
        showDialog={showDialog}
        title={preDialog ? "" : isEditProduct ? "View Product" : "Add New Product"}
        onClose={handleDialogClose}
        dialogContent={renderDialogContent()}
        dialogActions={!preDialog && renderDialogAction()}
      ></DialogBox>
    </React.Fragment>
    )
  };

  const handleSnackClose = () => {
    setSnpComponent({
        ...snpComponent,
        snackBarOpen: false,
        snackBarMsg: "",
    });
};

  return (
    <React.Fragment>
      <SnpComponent showDialog={snpComponent.showDialog}
        title={snpComponent.title}
        subTitle={snpComponent.subTitle}
        negativeAction={snpComponent.negativeAction}
        positiveReq={snpComponent.positiveReq}
        positiveAction={snpComponent.positiveAction}
        negativeReq={snpComponent.negativeReq}
        handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
        iconReq={snpComponent.iconReq}
        iconContent={snpComponent.iconContent}
        content={renderSnpComponent()}
        onSnackClose={handleSnackClose}
        cancelExtra={snpComponent.cancelExtra}
        onCancel={snpComponent.handleSnpCancel}
        snackBarOpen={snpComponent.snackBarOpen}
        snackBarMsg={snpComponent.snackBarMsg}
        outSideClickDismiss={snpComponent.outSideClickDismiss}
        handleSubmit={snpComponent.handleSnpSubmit}
        gui={snpComponent.gui}
      />
    </React.Fragment>
  );
}